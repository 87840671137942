import styled from 'styled-components';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { radius, RADIUS_SIZE } from 'design-system/styles/radius';
import { CurrencyConversionDisclaimer } from './partials/CurrencyConversionDisclaimer';

const BillingDisclaimerWrapper = styled.div`
  ${spacing.inset(SPACING_SIZE.M)};
  ${color.background(COLOR_PALETTE.PUMPKIN_A10)};
  ${radius.regular(RADIUS_SIZE.LARGE)};
`;

export default function BillingDisclaimer({
  showBillingConversion,
  convertedTotalPrice,
}) {
  if (!showBillingConversion) {
    return null;
  }

  return (
    <BillingDisclaimerWrapper>
      {showBillingConversion && (
        <CurrencyConversionDisclaimer convertedPrice={convertedTotalPrice} />
      )}
    </BillingDisclaimerWrapper>
  );
}
