import styled from 'styled-components';
import BreakdownTable from 'design-system/components/BreakdownTable/BreakdownTable';
import Tip from 'design-system/components/Tip';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';

import { useSelector } from 'reffects-store';
import { useEffect } from 'react';
import { dispatch } from 'reffects';
import { PrimaryButton } from 'design-system/components/Button/presets';
import { BUTTON_SIZE } from 'design-system/components/Button';
import useInternationalization from '../../../../hooks/useInternationalization';
import Price from '../../../../partials/Price/Price';

import {
  checkoutPublisherCountry,
  isSummaryLoadedSelector,
  summarySelector,
} from './selectors';
import { COUNTRY_LOCALE } from '../../../../constants/country';
import { FORCE_LOCALE } from '../../../../App/events';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';

const Wrapper = styled(Tip)`
  ${spacing.stack(SPACING_SIZE.M)};
`;

const StickyButton = styled(PrimaryButton)`
  display: inline;
  width: 100%;
`;

function CheckoutSummaryMobile({
  onClick,
  translateId,
  disabledButton,
  hiddeButton,
  ...props
}) {
  const intl = useInternationalization();
  const checkoutCountry = useSelector(checkoutPublisherCountry);

  const { totalPrice: finalPrice } = useSelector(summarySelector);
  useEffect(() => {
    if (
      checkoutCountry &&
      COUNTRY_LOCALE[checkoutCountry] &&
      !localStorage.getItem('localeForced')
    ) {
      dispatch({
        id: FORCE_LOCALE,
        payload: {
          locale: COUNTRY_LOCALE[checkoutCountry],
        },
      });
      localStorage.setItem('localeForced', 'true');
    }
  }, [checkoutCountry]);

  useEffect(() => {
    localStorage.removeItem('localeForced');
  }, []);

  const isLoaded = useSelector(isSummaryLoadedSelector);
  if (!isLoaded) {
    return null;
  }

  return (
    <>
      <Wrapper {...props}>
        <BreakdownTable>
          <BreakdownTable.TotalRow
            title={intl.formatMessageWithValidation({
              id: 'checkout_summary_total',
            })}
            price={
              <Price
                amount={finalPrice.amount}
                currency={finalPrice.currency}
              />
            }
          />
        </BreakdownTable>
      </Wrapper>
      {!hiddeButton && (
        <StickyButton
          onClick={onClick}
          disabled={disabledButton}
          $size={BUTTON_SIZE.LARGE}
        >
          <FormattedMessageWithValidation id={translateId} />
        </StickyButton>
      )}
    </>
  );
}

export default styled(CheckoutSummaryMobile)``;
