import { Route, Routes } from 'react-router-dom';
import { dispatch } from 'reffects';
import { ThemeProvider } from 'styled-components';
import { useSelector } from 'reffects-store';
import { PublicRoute } from './index';
import {
  CheckoutAlreadyActive,
  CheckoutBillingInformation,
  CheckoutCancelled,
  CheckoutConfirmation,
  CheckoutForbidden,
  CheckoutPaymentMethod,
  CheckoutProcessing,
} from '../../pages/Checkout';
import { CHECKOUT_ROUTE_NAVIGATED } from './events';
import CheckoutPageLoader from '../../pages/Checkout/CheckoutPageLoader/CheckoutPageLoader';
import { isMobileSelector } from '../selectors';

export default function CheckoutRoutes({ config }) {
  const { pageViewNamePrefix } = config;
  dispatch({ id: CHECKOUT_ROUTE_NAVIGATED, payload: config });
  const isMobile = useSelector(isMobileSelector);

  return (
    <ThemeProvider theme={{ isMobile }}>
      <RoutesList pageViewNamePrefix={pageViewNamePrefix} />
    </ThemeProvider>
  );
}

function RoutesList({ pageViewNamePrefix }) {
  return (
    <Routes>
      <Route
        path="billing-information"
        element={
          <PublicRoute
            as={CheckoutPageLoader}
            page={CheckoutBillingInformation}
            pageViewName={`${pageViewNamePrefix}-checkout-billing-information`}
            pageViewPayloadSelector={({
              subscriptionChangeRequestId,
              origin,
              cta,
            }) => ({
              subscriptionChangeRequestId,
              origin,
              cta,
            })}
            disableInterstitial
          />
        }
      />
      <Route
        path="payment-method"
        element={
          <PublicRoute
            as={CheckoutPageLoader}
            page={CheckoutPaymentMethod}
            pageViewName={`${pageViewNamePrefix}-checkout-payment-method`}
            pageViewPayloadSelector={({
              subscriptionChangeRequestId,
              origin,
              cta,
            }) => ({
              subscriptionChangeRequestId,
              origin,
              cta,
            })}
            disableInterstitial
          />
        }
      />
      <Route
        path="processing"
        element={
          <PublicRoute
            as={CheckoutPageLoader}
            page={CheckoutProcessing}
            pageViewName={`${pageViewNamePrefix}-checkout-processing`}
            pageViewPayloadSelector={({
              subscriptionChangeRequestId,
              origin,
              cta,
            }) => ({
              subscriptionChangeRequestId,
              origin,
              cta,
            })}
            disableInterstitial
          />
        }
      />
      <Route
        path="active"
        element={
          <PublicRoute
            as={CheckoutPageLoader}
            page={CheckoutAlreadyActive}
            pageViewName={`${pageViewNamePrefix}-checkout-already-active`}
            pageViewPayloadSelector={({
              subscriptionChangeRequestId,
              status,
            }) => ({
              subscriptionChangeRequestId,
              status,
            })}
            disableInterstitial
          />
        }
      />
      <Route
        path="cancelled"
        element={
          <PublicRoute
            as={CheckoutPageLoader}
            page={CheckoutCancelled}
            pageViewName={`${pageViewNamePrefix}-checkout-cancelled`}
            pageViewPayloadSelector={({
              subscriptionChangeRequestId,
              status,
            }) => ({
              subscriptionChangeRequestId,
              status,
            })}
            disableInterstitial
          />
        }
      />
      <Route
        path="forbidden"
        element={
          <PublicRoute
            as={CheckoutPageLoader}
            page={CheckoutForbidden}
            pageViewName={`${pageViewNamePrefix}-checkout-forbidden`}
            pageViewPayloadSelector={({
              subscriptionChangeRequestId,
              status,
            }) => ({
              subscriptionChangeRequestId,
              status,
            })}
            disableInterstitial
          />
        }
      />
      <Route
        path=":status"
        element={
          <PublicRoute
            as={CheckoutPageLoader}
            page={CheckoutConfirmation}
            pageViewName={`${pageViewNamePrefix}-checkout-confirmation`}
            pageViewPayloadSelector={({
              subscriptionChangeRequestId,
              status,
              origin,
              cta,
            }) => ({
              subscriptionChangeRequestId,
              status,
              origin,
              cta,
            })}
            disableInterstitial
          />
        }
      />
    </Routes>
  );
}
