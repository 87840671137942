import { dispatch } from 'reffects';
import { useSelector } from 'reffects-store';
import { useMount } from 'react-use';
import PaymentIframe from './PaymentIframe';
import {
  BACK_TO_BILLING_INFO,
  CHECKOUT_ERROR_PAGE,
  PAYMENT_GATEWAY_FORM_MOUNTED,
  PAYMENT_GATEWAY_FORM_OPERATION_SUCCEED,
} from './events';
import { paymentGatewayIframeSrcSelector } from './selectors';

function CreditCardIframe({ subscriptionChangeRequestId, ...props }) {
  useMount(() => {
    dispatch({
      id: PAYMENT_GATEWAY_FORM_MOUNTED,
      payload: { subscriptionChangeRequestId },
    });
  });

  const src = useSelector(paymentGatewayIframeSrcSelector);
  return (
    <PaymentIframe
      src={src}
      onSuccessEvent={{
        id: PAYMENT_GATEWAY_FORM_OPERATION_SUCCEED,
        payload: {
          subscriptionChangeRequestId,
          paymentMethod: 'credit-card',
        },
      }}
      onBackEvent={{
        id: BACK_TO_BILLING_INFO,
        payload: { subscriptionChangeRequestId },
      }}
      onCheckoutFailed={{
        id: CHECKOUT_ERROR_PAGE,
        payload: { subscriptionChangeRequestId },
      }}
      {...props}
    />
  );
}

export default CreditCardIframe;
