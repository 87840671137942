export const ERROR_TAGS = {
  'missing-address-coordinates':
    'ingester_stats_error_missing_address_coordinates',
  'missing-contactPhone': 'ingester_stats_error_missing_contact_phone',
  'missing-contactEmail': 'ingester_stats_error_missing_contact_email',
  'invalid-contactEmail': 'ingester_stats_error_invalid_contact_email',
  'missing-operations': 'ingester_stats_error_missing_operations',
  'invalid-operationType': 'ingester_stats_error_invalid_operation_type',
  'duplicated-operationType': 'ingester_stats_error_duplicated_operation_type',
  'sell-and-rent-not-supported':
    'ingester_stats_error_sell_and_rent_not_supported',
  'invalid-operationPrice': 'ingester_stats_error_invalid_operation_price',
  'missing-propertyType': 'ingester_stats_error_missing_property_type',
  'invalid-propertyType': 'ingester_stats_error_invalid_property_type',
  'missing-description': 'ingester_stats_error_missing_description',
  'missing-title': 'ingester_stats_error_missing_title',
  'internal-error': 'ingester_stats_error_internal_error',
  'invalid-coordinates': 'ingester_stats_error_invalid_coordinates',
  'missing-address': 'ingester_stats_error_missing_address',
  'invalid-title': 'ingester_stats_error_invalid_title',
  'invalid-description': 'ingester_stats_error_invalid_description',
  'invalid-referenceId': 'ingester_stats_error_invalid_reference_id',
  'invalid-stratum': 'ingester_stats_error_invalid_stratum',
  'invalid-locationVisibility':
    'ingester_stats_error_invalid_location_visibility',
  'invalid-priceCurrency': 'ingester_stats_error_invalid_price_currency',
  'invalid-communityFeesCurrency':
    'ingester_stats_error_invalid_community_fees_currency',
  'missing-communityFees': 'ingester_stats_error_missing_community_fees',
  'invalid-furnished': 'ingester_stats_error_invalid_furnished',
  'invalid-ownership': 'ingester_stats_error_invalid_ownership',
  'invalid-amenities': 'ingester_stats_error_invalid_amenities',
  'duplicated-amenities': 'ingester_stats_error_duplicated_amenities',
  'duplicated-nearbyLocations':
    'ingester_stats_error_duplicated_nearby_locations',
  'invalid-nearbyLocations': 'ingester_stats_error_invalid_nearby_locations',
  'invalid-rules': 'ingester_stats_error_invalid_rules',
  'duplicated-rules': 'ingester_stats_error_duplicated_rules',
  'invalid-condition': 'ingester_stats_error_invalid_condition',
  'invalid-efficiency': 'ingester_stats_error_invalid_efficiency',
  'invalid-emissions': 'ingester_stats_error_invalid_emissions',
  'invalid-orientation': 'ingester_stats_error_invalid_orientation',
  'invalid-halfBathrooms': 'ingester_stats_error_invalid_half_bathrooms',
  'invalid-bathrooms': 'ingester_stats_error_invalid_bathrooms',
  'invalid-parking-spaces': 'ingester_stats_error_invalid_parking_spaces',
  'invalid-bedrooms': 'ingester_stats_error_invalid_bedrooms',
  'invalid-spaces': 'ingester_stats_error_invalid_spaces',
  'invalid-floorArea': 'ingester_stats_error_invalid_floor_area',
  'invalid-usableArea': 'ingester_stats_error_invalid_usable_area',
  'invalid-constructionYear': 'ingester_stats_error_invalid_construction_year',
  'below-minimum-tenureInYears':
    'ingester_stats_error_below_minimum_tenure_in_years',
  'below-minimum-communityFeesAmount':
    'ingester_stats_error_below_minimum_community_fees_amount',
  'invalid-virtualTours': 'ingester_stats_error_invalid_virtual_tours',
  'duplicated-virtualTours': 'ingester_stats_error_duplicated_virtual_tours',
  'invalid-floorPlans': 'ingester_stats_error_invalid_floor_plans',
  'duplicated-floorPlans': 'ingester_stats_error_duplicated_floor_plans',
  'invalid-suitableForCredit':
    'ingester_stats_error_invalid_suitable_for_credit',
  'invalid-plotArea': 'ingester_stats_error_invalid_plot_area',
  'invalid-contactFacebookMessenger': 'ingester_stats_error_invalid_contact',
  'invalid-contactLine': 'ingester_stats_error_invalid_contact',
  'invalid-contactSkype': 'ingester_stats_error_invalid_contact',
  'invalid-contactViber': 'ingester_stats_error_invalid_contact',
};
