import styled from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import CheckoutStepper from './CheckoutStepper/CheckoutStepper';

const Wrapper = styled.div`
  ${(props) =>
    props.theme?.isMobile
      ? spacing.insetSandwich(SPACING_SIZE.M)
      : spacing.inset(SPACING_SIZE.XL)}
`;

export default function Sidebar({ currentStep, currentStepStatus }) {
  return (
    <Wrapper>
      <CheckoutStepper
        currentStep={currentStep}
        currentStepStatus={currentStepStatus}
      />
    </Wrapper>
  );
}
