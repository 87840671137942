import { useEffect, useState } from 'react';
import { useSelector } from 'reffects-store';
import { useUnmount } from 'react-use';
import styled from 'styled-components';
import { useScript } from '../../hooks/useScript';
import { enableHubspotSelector, hubspotUserLocaleSelector } from './selectors';
import {
  getPublisherMaxBoostablePropertiesFromSubscriptionSelector,
  isDeveloperSelector,
  isSelfServicePublisherSelector,
  publisherCountrySelector,
  publisherIdSelector,
} from '../../selectors/publisher';
import { userEmailSelector, userIdSelector } from '../../selectors/user';

const HUBSPOT_SELF_SERVICE_PUBLISHER = 'self-service';
const HUBSPOT_SALES_ASSISTED_PUBLISHER = 'sales-assisted';
const widgetSpacing = 80;
const SpacedDiv = styled.div`
  height: ${widgetSpacing}px;
`;

function useVerticalScrollStatusChanged(f) {
  let previousScrollHeight = document.body.scrollHeight;
  let previousInnerHeight = window.innerHeight + widgetSpacing;
  let requestId;

  const checkIfItHasVerticalScroll = () => {
    const currentScrollHeight = document.body.scrollHeight;
    const currentInnerHeight = window.innerHeight + widgetSpacing;

    if (
      currentScrollHeight !== previousScrollHeight ||
      currentInnerHeight !== previousInnerHeight
    ) {
      previousScrollHeight = currentScrollHeight;
      previousInnerHeight = currentInnerHeight;
      f(currentScrollHeight > currentInnerHeight);
    }
    requestId = requestAnimationFrame(checkIfItHasVerticalScroll);
  };

  checkIfItHasVerticalScroll();

  return () => cancelAnimationFrame(requestId);
}

function Widget() {
  const userId = useSelector(userIdSelector);
  const userEmail = useSelector(userEmailSelector);
  const locale = useSelector(hubspotUserLocaleSelector);
  const country = useSelector(publisherCountrySelector);
  const publisherId = useSelector(publisherIdSelector);
  const isDeveloper = useSelector(isDeveloperSelector);
  const maxBoostableProperties = useSelector(
    getPublisherMaxBoostablePropertiesFromSubscriptionSelector
  );
  const isSelfService = useSelector(isSelfServicePublisherSelector);
  const loadStatus = useScript('//js.hs-scripts.com/6693398.js', {
    id: 'hs-script-loader',
    removeOnUnmount: false,
  });
  useEffect(() => {
    // eslint-disable-next-line no-underscore-dangle,no-multi-assign
    window._hsq = window._hsq = window._hsq || [];
    const trackedParams = {
      email: userEmail,
      user_uuid: userId,
      publisher_uuid: publisherId,
      hs_language: locale,
      country,
      publisherType: isDeveloper ? 'developer' : 'agency',
      user_contraction_type: isSelfService
        ? HUBSPOT_SELF_SERVICE_PUBLISHER
        : HUBSPOT_SALES_ASSISTED_PUBLISHER,
      contracted_properties: maxBoostableProperties,
    };
    // eslint-disable-next-line no-underscore-dangle
    window._hsq.push(['identify', trackedParams]);
  }, [
    userEmail,
    userId,
    publisherId,
    locale,
    country,
    isDeveloper,
    isSelfService,
    maxBoostableProperties,
  ]);
  useEffect(() => {
    if (loadStatus === 'ready') {
      if (window.HubSpotConversations) {
        window.HubSpotConversations.widget.load();
      } else {
        window.hsConversationsOnReady = [
          () => window.HubSpotConversations.widget.load(),
        ];
      }
    }
  }, [loadStatus]);
  const [hasVerticalScroll, setHasVerticalScroll] = useState(false);

  useEffect(() => useVerticalScrollStatusChanged(setHasVerticalScroll), []);

  useUnmount(() => {
    if (window.HubSpotConversations) {
      window.HubSpotConversations.widget.remove();
    }
  });

  return hasVerticalScroll ? <SpacedDiv /> : <div />;
}
export default function HubspotWidget() {
  const enabled = useSelector(enableHubspotSelector);
  return !enabled ? null : <Widget />;
}
