import { useSelector } from 'reffects-store';
import styled from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import CheckoutSummary from './CheckoutSummary';
import BillingDisclaimer from '../BillingDisclaimer/BillingDisclaimer';
import { isSummaryLoadedSelector, summarySelector } from './selectors';
import RenewalPriceDisclaimer from './RenewalPriceDisclaimer';
import { useFeatureSelector } from '../../../../utils/featuresFlags/selectors';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.value(SPACING_SIZE.M)};
`;

function Summary(props) {
  const summary = useSelector(summarySelector);
  const isLoaded = useSelector(isSummaryLoadedSelector);
  const { convertedTotalPrice, renewalPrice } = summary;
  const SHOW_RENEWAL_PRICE_4184 = useFeatureSelector('SHOW_RENEWAL_PRICE_4184');

  if (!isLoaded) {
    return null;
  }

  return (
    <Wrapper {...props}>
      <CheckoutSummary {...summary} />
      {SHOW_RENEWAL_PRICE_4184 && renewalPrice && (
        <RenewalPriceDisclaimer renewalPrice={renewalPrice} />
      )}
      <BillingDisclaimer
        showBillingConversion={convertedTotalPrice != null}
        convertedTotalPrice={convertedTotalPrice}
      />
    </Wrapper>
  );
}

export default styled(Summary)``;
