import { coeffect, registerCoeffectHandler } from 'reffects';
import { UAParser } from 'ua-parser-js';
import { DESKTOP_DEVICE, MOBILE_DEVICE } from '../constants/deviceType';

const COEFFECT_NAME = 'device';

export default function registerDeviceCoeffect(userAgent) {
  registerCoeffectHandler(COEFFECT_NAME, () => {
    const {
      os: { name },
      device: { type },
    } = UAParser(userAgent);
    return {
      [COEFFECT_NAME]: {
        os: name,
        type: type === 'mobile' ? MOBILE_DEVICE : DESKTOP_DEVICE,
      },
    };
  });
}

export function device() {
  return coeffect(COEFFECT_NAME);
}
