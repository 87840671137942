import styled from 'styled-components';
import { useSelector } from 'reffects-store';
import { useIntl } from 'react-intl';
import { Fragment } from 'react';
import Anchor from 'design-system/components/Anchor';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Overline } from 'design-system/components/Typography/presets/Overline';
import {
  isSubscriptionChangeRequestDataLoaded,
  subscriptionChangeRequestDetailsSelector,
  subscriptionChangeRequestPaymentDetailsSelector,
} from './selectors';
import CustomFormattedDate from '../../../../partials/FormattedDate/CustomFormattedDate';
import getDiscountNameString from '../../../utils/getDiscountNameString';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { SUBSCRIPTION_TYPES } from '../../../../constants/subscription';
import Price, { formatPrice } from '../../../../partials/Price/Price';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Block = styled.div`
  ${spacing.stack(SPACING_SIZE.XL)}

  ${Overline} {
    ${spacing.stack(SPACING_SIZE.S)}
  }

  ul {
    padding: 0;
  }

  li {
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    line-height: 31px;
    padding: 0 12px;
    border-color: var(--divider-color);
    border-style: solid;
    border-width: 1px 1px 0 1px;

    &:first-of-type {
      border-radius: 4px 4px 0 0;
    }

    &:last-of-type {
      border-bottom: 1px solid var(--divider-color);
      border-radius: 0 0 4px 4px;
    }

    span {
      ${spacing.inline(SPACING_SIZE.M)}
    }
  }
`;

function Discounts({
  discounts,
  unusedPreviousSubscriptionDays,
  unusedCurrentSubscriptionDays,
}) {
  return discounts.map(({ name, amount, currency }) => {
    const discountName = getDiscountNameString(
      name,
      unusedPreviousSubscriptionDays,
      unusedCurrentSubscriptionDays
    );
    if (!discountName) return null;
    return (
      <li key={name}>
        <span>{discountName}</span>
        <span>
          <Price amount={amount * -1} currency={currency} />
        </span>
      </li>
    );
  });
}

export default function RequestSubscriptionChangeDetails() {
  const isLoaded = useSelector(isSubscriptionChangeRequestDataLoaded);
  const requestDetail = useSelector(subscriptionChangeRequestDetailsSelector);
  const intl = useIntl();
  const { link } = useSelector(subscriptionChangeRequestPaymentDetailsSelector);

  if (!isLoaded) {
    return null;
  }

  const {
    products,
    billingCycleInMonths,
    startDate,
    nextBillingDate,
    taxRetentions,
    vat,
    total,
    wasi,
    paymentMethod,
  } = requestDetail;

  return (
    <Wrapper>
      <Block>
        <Overline>Request details</Overline>
        <ul>
          {products.map((p) => (
            <Fragment key={p.type}>
              {p.type === SUBSCRIPTION_TYPES.BASE_SUBSCRIPTION && (
                <li>
                  <span>Plan</span>{' '}
                  <span>
                    <FormattedMessageWithValidation id={p.nameTag} />
                  </span>
                </li>
              )}
              <li>
                <span>{`${
                  p.type === SUBSCRIPTION_TYPES.SUPERBOOST
                    ? 'Superboosted'
                    : 'Boosted'
                } properties`}</span>{' '}
                <span>{p.maxBoostableProperties} properties</span>
              </li>
            </Fragment>
          ))}
          <li>
            <span>Duration</span> <span>{billingCycleInMonths} months</span>
          </li>
          {startDate && (
            <li>
              <span>Start date</span>{' '}
              <span>
                <CustomFormattedDate value={startDate} />
              </span>
            </li>
          )}
          {nextBillingDate && (
            <li>
              <span>Next billing date</span>{' '}
              <span>
                <CustomFormattedDate value={nextBillingDate} />
              </span>
            </li>
          )}
          {wasi && (
            <>
              <li>
                <span>Wasi Plan</span> <span>{wasi.planName}</span>
              </li>
              {wasi.extraUsers && (
                <li>
                  <span>Extra Users</span> <span>{wasi.extraUsers}</span>
                </li>
              )}
            </>
          )}
        </ul>
      </Block>
      <Block>
        <Overline>Billing detail</Overline>
        <ul>
          {products.map(
            ({
              type,
              maxBoostableProperties,
              billingDetail,
              unusedPreviousSubscriptionDays,
              unusedCurrentSubscriptionDays,
            }) => (
              <Fragment key={type}>
                <li>
                  <span>
                    {type === SUBSCRIPTION_TYPES.SUPERBOOST
                      ? `Superboost x ${maxBoostableProperties}`
                      : `Monthly plan price ${formatPrice(
                          intl,
                          billingDetail.unitPrice.amount,
                          billingDetail.unitPrice.currency
                        )} x ${billingCycleInMonths} months`}
                  </span>
                  <span>
                    <Price
                      amount={billingDetail.basePrice.amount}
                      currency={billingDetail.basePrice.currency}
                    />
                  </span>
                </li>
                {billingDetail.proportionalPriceForRemainingDays != null && (
                  <li>
                    <span>
                      Proportional price for remainig days (
                      {billingDetail.unusedPreviousSubscriptionDays} days)
                    </span>
                    <span>
                      <Price
                        amount={
                          billingDetail.proportionalPriceForRemainingDays.amount
                        }
                        currency={
                          billingDetail.proportionalPriceForRemainingDays
                            .currency
                        }
                      />
                    </span>
                  </li>
                )}
                <Discounts
                  discounts={billingDetail.discounts}
                  unusedPreviousSubscriptionDays={
                    unusedPreviousSubscriptionDays
                  }
                  unusedCurrentSubscriptionDays={unusedCurrentSubscriptionDays}
                />
              </Fragment>
            )
          )}
          {wasi && (
            <>
              <li>
                <span>Wasi Plan</span>
                <span>
                  <Price
                    amount={wasi.planBillingDetail.basePrice.amount}
                    currency={wasi.planBillingDetail.basePrice.currency}
                  />
                </span>
              </li>
              <Discounts discounts={wasi.planBillingDetail.discounts} />
              {wasi.extraUsersBillingDetail && (
                <>
                  <li>
                    <span>Wasi Extra Users</span>
                    <span>
                      <Price
                        amount={wasi.extraUsersBillingDetail.basePrice.amount}
                        currency={
                          wasi.extraUsersBillingDetail.basePrice.currency
                        }
                      />
                    </span>
                  </li>
                  <Discounts
                    discounts={wasi.extraUsersBillingDetail.discounts}
                  />
                </>
              )}
            </>
          )}
          {taxRetentions.map(({ name, amount, currency }) => (
            <li>
              <span>{name}</span>
              <span>
                <Price amount={amount * -1} currency={currency} />
              </span>
            </li>
          ))}
          <li>
            <span>
              {vat.name} {vat.percentage}%
            </span>
            <span>
              <Price amount={vat.amount} currency={vat.currency} />
            </span>
          </li>
          {products.map(({ type, billingDetail: { total: productTotal } }) => (
            <li key={type}>
              <strong>{`Total ${
                type === SUBSCRIPTION_TYPES.SUPERBOOST
                  ? 'superboost'
                  : 'subscription'
              }`}</strong>
              <strong>
                <Price
                  amount={productTotal.amount}
                  currency={productTotal.currency}
                />
              </strong>
            </li>
          ))}
          {wasi && (
            <>
              <li>
                <strong>Total Wasi</strong>
                <strong>
                  <Price
                    amount={wasi.total.amount}
                    currency={wasi.total.currency}
                  />
                </strong>
              </li>
            </>
          )}
          <li>
            <strong>Total</strong>
            <strong>
              <Price amount={total.amount} currency={total.currency} />
            </strong>
          </li>
        </ul>
      </Block>
      <Block>
        <Overline>Payment info</Overline>
        <ul>
          <li>
            <span>Link</span>{' '}
            <span>
              <Anchor href={link}>{link}</Anchor>
            </span>
          </li>
          <li>
            <span>Payment method</span> <span>{paymentMethod}</span>
          </li>
        </ul>
      </Block>
    </Wrapper>
  );
}
