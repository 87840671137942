import { useIntl } from 'react-intl';
import styled from 'styled-components';
import {
  CURRENT_SUBSCRIPTION_DISCOUNT_NAME,
  CUSTOM_DISCOUNT_NAME,
  PREVIOUS_SUBSCRIPTION_DISCOUNT_NAME,
  SUBSCRIPTION_TYPES,
  UPFRONT_DISCOUNT_NAME,
} from '../../../../../../../constants/subscription';
import Price, { formatPrice } from '../../../../../../../partials/Price/Price';
import { useFeatureSelector } from '../../../../../../../utils/featuresFlags/selectors';

function getTaxDiscountName(name, percentage) {
  const percentageFormatted = percentage.toString().replace('.', ',');
  // eslint-disable-next-line default-case
  switch (name) {
    case 'retefuenteCO':
      return `${percentageFormatted}% off for Retefuente`;
    case 'reteIcaCO':
      return `${percentageFormatted}% off for ReteIca`;
    case 'reteIvaCO':
      return `${percentageFormatted}% off for ReteIva`;
  }

  return '';
}

const SummaryRowWrapper = styled.p`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: var(--4px-rem) var(--12px-rem);
  font-size: var(--font-16);
  border: 1px solid var(--divider-color);
  color: var(--gray-color-87);
  border-bottom: 0;

  &:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom: 1px solid var(--divider-color);
    font-weight: var(--font-weight-boldest);
  }
`;

function SummaryRow({ title, value }) {
  return (
    <SummaryRowWrapper>
      <span>{title}</span>
      <span>{value}</span>
    </SummaryRowWrapper>
  );
}

function SummarySubscription({ subscription, selectedBillingCycle }) {
  const intl = useIntl();
  const upfrontPaymentDiscount = subscription.discounts.find(
    ({ name }) => name === UPFRONT_DISCOUNT_NAME
  );
  const customDiscount = subscription.discounts.find(
    ({ name }) => name === CUSTOM_DISCOUNT_NAME
  );
  const previousSubscriptionDiscount = subscription.discounts.find(
    ({ name }) => name === PREVIOUS_SUBSCRIPTION_DISCOUNT_NAME
  );
  const currentSubscriptionDiscount = subscription.discounts.find(
    ({ name }) => name === CURRENT_SUBSCRIPTION_DISCOUNT_NAME
  );
  const {
    unusedPreviousSubscriptionDays,
    unusedCurrentSubscriptionDays,
    proportionalPriceForRemainingDays,
  } = subscription;

  const planPriceLabel = () => {
    if (subscription.productType === SUBSCRIPTION_TYPES.SUPERBOOST)
      return `Superboost x ${subscription.boostableProperties}`;
    return `Monthly plan price ${formatPrice(
      intl,
      subscription.price.amount,
      subscription.price.currency
    )} x ${subscription.billingCycleInMonths} months`;
  };

  return (
    <>
      <SummaryRow
        title={<>{planPriceLabel()}</>}
        value={
          <>
            <Price
              amount={subscription.basePrice.amount}
              currency={subscription.basePrice.currency}
            />
          </>
        }
      />
      {proportionalPriceForRemainingDays && (
        <SummaryRow
          title={`Proportional for ${unusedPreviousSubscriptionDays} remaining days`}
          value={
            <>
              <Price
                amount={proportionalPriceForRemainingDays.amount}
                currency={proportionalPriceForRemainingDays.currency}
              />
            </>
          }
        />
      )}
      {upfrontPaymentDiscount && (
        <SummaryRow
          title={`${upfrontPaymentDiscount.percentage}% off for ${selectedBillingCycle} months contracts`}
          value={
            <Price
              amount={upfrontPaymentDiscount.amount * -1}
              currency={upfrontPaymentDiscount.currency}
            />
          }
        />
      )}
      {customDiscount && (
        <SummaryRow
          title={`${customDiscount.percentage.toFixed(
            2
          )}% off for custom discount`}
          value={
            <Price
              amount={customDiscount.amount * -1}
              currency={customDiscount.currency}
            />
          }
        />
      )}
      {previousSubscriptionDiscount && (
        <SummaryRow
          title={`Previous subscription discount for unused ${
            unusedPreviousSubscriptionDays ?? ''
          } days`}
          value={
            <Price
              amount={previousSubscriptionDiscount.amount * -1}
              currency={previousSubscriptionDiscount.currency}
            />
          }
        />
      )}
      {currentSubscriptionDiscount && (
        <SummaryRow
          title={`New subscription discount for ${
            unusedCurrentSubscriptionDays ?? ''
          } unused days`}
          value={
            <Price
              amount={currentSubscriptionDiscount.amount * -1}
              currency={currentSubscriptionDiscount.currency}
            />
          }
        />
      )}
    </>
  );
}

function WasiSummary({ plan, extraUsers }) {
  const findDiscount = (discounts, discountName) =>
    discounts.find(({ name }) => name === discountName);
  const planUpfrontDiscount = findDiscount(
    plan.discounts,
    UPFRONT_DISCOUNT_NAME
  );
  const planCustomDiscount = findDiscount(plan.discounts, CUSTOM_DISCOUNT_NAME);
  const extraUsersUpfrontDiscount = findDiscount(
    extraUsers?.discounts ?? [],
    UPFRONT_DISCOUNT_NAME
  );
  const extraUsersCustomDiscount = findDiscount(
    extraUsers?.discounts ?? [],
    CUSTOM_DISCOUNT_NAME
  );

  return (
    <>
      <SummaryRow
        title="Wasi price"
        value={
          <>
            <Price
              amount={plan.basePrice.amount}
              currency={plan.basePrice.currency}
            />
          </>
        }
      />
      {planUpfrontDiscount && (
        <SummaryRow
          title={`${planUpfrontDiscount.percentage}% off upfront payment`}
          value={
            <Price
              amount={planUpfrontDiscount.amount * -1}
              currency={planUpfrontDiscount.currency}
            />
          }
        />
      )}
      {planCustomDiscount && (
        <SummaryRow
          title={`${planCustomDiscount.percentage.toFixed(
            2
          )}% off for custom discount`}
          value={
            <Price
              amount={planCustomDiscount.amount * -1}
              currency={planCustomDiscount.currency}
            />
          }
        />
      )}
      {extraUsers != null && (
        <>
          <SummaryRow
            title="Extra users price"
            value={
              <>
                <Price
                  amount={extraUsers.basePrice.amount}
                  currency={extraUsers.basePrice.currency}
                />
              </>
            }
          />
          {extraUsersUpfrontDiscount && (
            <SummaryRow
              title={`${extraUsersUpfrontDiscount.percentage}% off upfront payment`}
              value={
                <Price
                  amount={extraUsersUpfrontDiscount.amount * -1}
                  currency={extraUsersUpfrontDiscount.currency}
                />
              }
            />
          )}
          {extraUsersCustomDiscount && (
            <SummaryRow
              title={`${extraUsersCustomDiscount.percentage.toFixed(
                2
              )}% off for custom discount`}
              value={
                <Price
                  amount={extraUsersCustomDiscount.amount * -1}
                  currency={extraUsersCustomDiscount.currency}
                />
              }
            />
          )}
        </>
      )}
    </>
  );
}

const SummaryTitle = styled.p`
  margin-bottom: var(--spacing-xs);
  font-size: 10px;
  font-weight: var(--font-weight-bolder);
  line-height: 1.6;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: var(--gray-color-60);
`;

export default function Summary({
  subscriptionRenewalSummary: {
    subscriptions,
    wasi,
    taxApplied,
    taxDiscounts = [],
    totalPrice,
    totalRenewalPrice,
  },
  selectedBillingCycle,
}) {
  const UPSELL_WITH_CARD_4183 = useFeatureSelector('UPSELL_WITH_CARD_4183');
  const totalPriceLabel = (productType) => {
    if (productType === SUBSCRIPTION_TYPES.SUPERBOOST)
      return 'Total superboost';
    return 'Total subscription';
  };

  return (
    <>
      <SummaryTitle>SUMMARY</SummaryTitle>
      {subscriptions.map((subscription) => (
        <SummarySubscription
          key={subscription.productType}
          subscription={subscription}
          selectedBillingCycle={selectedBillingCycle}
        />
      ))}
      {wasi && <WasiSummary plan={wasi.plan} extraUsers={wasi.extraUsers} />}
      {taxApplied.amount > 0 && (
        <SummaryRow
          title={`${taxApplied.name} ${taxApplied.percentage}%`}
          value={
            <Price amount={taxApplied.amount} currency={taxApplied.currency} />
          }
        />
      )}
      {taxDiscounts.map(({ amount, currency, name, percentage }) => (
        <SummaryRow
          title={getTaxDiscountName(name, percentage)}
          value={
            <>
              <Price amount={amount * -1} currency={currency} />
            </>
          }
          key={name}
        />
      ))}
      {subscriptions.map(({ productType, totalPrice: productPrice }) => (
        <SummaryRow
          key={productType}
          title={<strong>{totalPriceLabel(productType)}</strong>}
          value={
            <Price
              amount={productPrice.amount}
              currency={productPrice.currency}
            />
          }
        />
      ))}
      {wasi && (
        <SummaryRow
          title={<strong>Total Wasi</strong>}
          value={
            <Price amount={wasi.total.amount} currency={wasi.total.currency} />
          }
        />
      )}
      <SummaryRow
        title="Total"
        value={
          <Price amount={totalPrice.amount} currency={totalPrice.currency} />
        }
      />
      {UPSELL_WITH_CARD_4183 && totalRenewalPrice && (
        <SummaryRow
          title="Renewal Price"
          value={
            <Price
              amount={totalRenewalPrice.amount}
              currency={totalRenewalPrice.currency}
            />
          }
        />
      )}
    </>
  );
}
