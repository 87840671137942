import { registerEventHandler } from 'reffects';
import { http } from 'reffects-batteries';
import { state } from 'reffects-store';
import queryString from 'query-string';
import { environment } from '../../coeffects/environment';
import { location } from '../../coeffects/location';
import { analytics } from '../../effects/analytics';
import {
  SELF_SERVICE_CREATE,
  SELF_SERVICE_ERROR,
} from '../../constants/routes';
import { navigateTo, redirectTo } from '../../effects/routing';

export const SELFSERVICE_LOAD_PLAN_DETAILS = 'SELFSERVICE_LOAD_PLAN_DETAILS';
export const SELFSERVICE_LOAD_PLAN_DETAILS_SUCCESS =
  'SELFSERVICE_LOAD_PLAN_DETAILS_SUCCESS';
export const SELFSERVICE_CREATE_SUBMITTED = 'SELFSERVICE_CREATE_SUBMITTED';
export const SELFSERVICE_CREATE_FAILED = 'SELFSERVICE_CREATE_FAILED';
export const SELFSERVICE_CREATED_SUCCESS = 'SELFSERVICE_CREATED_SUCCESS';
export const SELFSERVICE_ERROR_GO_BACK = 'SELFSERVICE_ERROR_GO_BACK';

const PROPPIT_ORIGIN = 'proppit-form';

registerEventHandler(
  SELFSERVICE_LOAD_PLAN_DETAILS,
  ({
    environment: { apiUrl },
    location: { queryString: receivedQueryString },
  }) => {
    const { plan } = queryString.parse(receivedQueryString);

    return http.get({
      url: `${apiUrl}/self-service-plan/${plan}`,
      successEvent: [SELFSERVICE_LOAD_PLAN_DETAILS_SUCCESS],
    });
  },
  [environment(), location()]
);

registerEventHandler(SELFSERVICE_LOAD_PLAN_DETAILS_SUCCESS, (_, [{ data }]) =>
  state.set({
    selfService: {
      planDetails: data,
    },
  })
);

registerEventHandler(
  SELFSERVICE_CREATE_SUBMITTED,
  (
    {
      environment: { apiUrl },
      location: { queryString: receivedQueryString },
      state: { planDetails },
    },
    { email, companyName, phone }
  ) => {
    const phoneNumber = phone.dialCode + phone.phoneNumber;
    const { countryCode, plan, origin, cta } =
      queryString.parse(receivedQueryString);
    let payload = {
      email,
      companyName,
      phone: phoneNumber,
      country: countryCode,
      planId: plan,
      offerName: planDetails.offerName,
    };

    if (origin && cta) {
      payload = {
        ...payload,
        origin,
        cta,
      };
    }

    const url = new URL(`${apiUrl}/self-service/publisher/create`);
    url.searchParams.append('email', email);
    url.searchParams.append('company', companyName);
    url.searchParams.append('phone', phoneNumber);
    url.searchParams.append('origin', PROPPIT_ORIGIN);
    url.searchParams.append('country', countryCode);
    url.searchParams.append('planId', plan);

    return {
      ...analytics.trackClick(
        `self-service-create-form-submit-${countryCode}`,
        'self-service-create-form',
        payload
      ),
      ...http.get({
        url: url.toString(),
        successEvent: [SELFSERVICE_CREATED_SUCCESS, payload],
        errorEvent: [SELFSERVICE_CREATE_FAILED, payload],
      }),
    };
  },
  [
    environment(),
    location(),
    state.get({
      planDetails: 'selfService.planDetails',
    }),
  ]
);

registerEventHandler(
  SELFSERVICE_CREATED_SUCCESS,
  (_, [{ data }, payload]) => ({
    ...analytics.trackClick(
      `self-service-create-form-success-${payload.country}`,
      'self-service-create-form',
      payload
    ),
    ...redirectTo(data.link, true),
  }),
  []
);

registerEventHandler(
  SELFSERVICE_CREATE_FAILED,
  (_, [, payload]) => ({
    ...analytics.trackClick(
      `self-service-create-form-error-user-exist-${payload.country}`,
      'self-service-create-form',
      payload
    ),
    ...navigateTo(SELF_SERVICE_ERROR, true),
    ...state.set({
      selfService: {
        email: payload.email,
        country: payload.country,
        planId: payload.planId,
      },
    }),
  }),
  []
);

registerEventHandler(
  SELFSERVICE_ERROR_GO_BACK,
  () => ({
    ...navigateTo(SELF_SERVICE_CREATE, true),
  }),
  []
);
