import styled from 'styled-components';
import { radius, RADIUS_SIZE } from 'design-system/styles/radius';
import { border, BORDER_SIZE } from 'design-system/styles/border';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import {
  FONT_FAMILY,
  FONT_WEIGHT,
  typography,
} from 'design-system/styles/typography';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import Price from '../../../partials/Price/Price';

const OfferMobile = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  ${radius.regular(RADIUS_SIZE.LARGE)}
  ${border.outer({
    size: BORDER_SIZE.LARGE,
    color: COLOR_PALETTE.PRIMARY_BASE,
  })}
  ${color.background(COLOR_PALETTE.NEUTRAL_A00)};
  padding: ${spacing.value(SPACING_SIZE.M)};
  flex-direction: row;
  width: 100%;
  gap: ${spacing.value(SPACING_SIZE.M)};
  max-width: 415px;
}
`;

const OfferDetailsMobile = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
  text-transform: uppercase;
  ${typography.weight(FONT_WEIGHT.BOLD)}
  ${typography.size(15)}
  > span:first-child {
    ${color.text(COLOR_PALETTE.PRIMARY_BASE)};
    ${typography.weight(800)}
    ${typography.size(13)}
  }
  > span:last-child {
    ${color.text(COLOR_PALETTE.SECONDARY_A60)};
  }
`;

const PricesMobile = styled.div`
  min-width: 128px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  border-right: 1px solid ${COLOR_PALETTE.NEUTRAL_A20};
  padding-right: ${spacing.value(SPACING_SIZE.S)};
`;

const OfferPriceMobile = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  ${Price} {
    ${color.text(COLOR_PALETTE.PRIMARY_A120)}
    text-align: center;
    font-style: normal;
    line-height: normal;
    letter-spacing: -1px;
    padding: 0;
    ${typography.size(25)}
    ${typography.weight(FONT_WEIGHT.MEDIUM)}
  }
  > span {
    ${color.text(COLOR_PALETTE.PRIMARY_A120)}
    ${typography.size(16)}
    font-family: ${FONT_FAMILY.PRIMARY};
    ${typography.weight(FONT_WEIGHT.MEDIUM)};
    padding-left: 2px;
  }
`;

const OriginalPriceMobile = styled.div`
  text-align: center;
  ${Price} {
    ${color.text(COLOR_PALETTE.NEUTRAL_A60)};
    ${typography.size(10)}
    font-style: normal;
    font-weight: 400;
    text-decoration: line-through;
  }
`;

const OfferDurationMobile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;

  span {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    ${color.text(COLOR_PALETTE.NEUTRAL_A60)};
    ${typography.size(12)}
    ${typography.lineHeight(12)}
  }
`;
export default function OffersCardMobile({
  offerData,
  originalPrice,
  offerPrice,
  onClick,
}) {
  return (
    <OfferMobile onClick={onClick}>
      <PricesMobile>
        {Boolean(offerData.totalDiscountPercentage) && (
          <OriginalPriceMobile>
            <Price amount={originalPrice} currency={offerData.currency} />
          </OriginalPriceMobile>
        )}
        <OfferPriceMobile>
          <Price amount={offerPrice} currency={offerData.currency} />
          <span>*</span>
        </OfferPriceMobile>
        <OfferDurationMobile>
          {offerData.billingCycleInMonths === 1 ? (
            <FormattedMessageWithValidation id="subscription_landing_plan_duration_monthly" />
          ) : (
            <FormattedMessageWithValidation id="subscription_landing_plan3_duration" />
          )}
        </OfferDurationMobile>
      </PricesMobile>
      <OfferDetailsMobile>
        <FormattedMessageWithValidation id="subscription_landing_agency_pack" />
        {offerData.maxBoostableProperties === 1 ? (
          <FormattedMessageWithValidation id="subscription_landing_plan_base_single" />
        ) : (
          <FormattedMessageWithValidation
            id="subscription_landing_plan_base"
            values={{
              amount: offerData.maxBoostableProperties,
            }}
          />
        )}
        {offerData.maxSuperboostableProperties === 1 ? (
          <FormattedMessageWithValidation id="subscription_landing_plan_superboost_single" />
        ) : (
          <FormattedMessageWithValidation
            id="subscription_landing_plan_superboost"
            values={{
              amount: offerData.maxSuperboostableProperties,
            }}
          />
        )}
      </OfferDetailsMobile>
    </OfferMobile>
  );
}
