import styled, { css } from 'styled-components';
import { dispatch } from 'reffects';
import { useEffect } from 'react';
import { useSelector } from 'reffects-store';
import Loader from 'design-system/components/Loader';
import Grid from 'design-system/components/Grid';
import Layout from 'design-system/components/Layout';
import { Heading2 } from 'design-system/components/Typography/presets/Heading2';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import {
  FONT_SIZE,
  FONT_WEIGHT,
  typography,
} from 'design-system/styles/typography';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { Navigation } from '../../../partials';
import Sidebar from '../partials/Sidebar';
import { CHECKOUT_STEPS } from '../partials/CheckoutStepper/CheckoutStepper';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { CHECKOUT_FIRST_PAYMENT_PROCESSING_PAGE_MOUNTED } from './events';
import {
  checkoutFailedRoute,
  checkoutSuccessRoute,
} from '../../../utils/proppitWebRouter';
import { checkoutSubRouteSelector } from '../configuration/selectors';
import { checkoutPageSCRIdSelector } from '../selectors';
import PublisherData from '../PublisherData/PublisherData';

const Heading = styled(Heading2)`
  display: block;
  ${spacing.stack(SPACING_SIZE.M)}
  ${typography.weight(FONT_WEIGHT.NORMAL)}
`;

const LoaderWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${spacing.stack(SPACING_SIZE.M)}
`;

const Content = styled(Layout.Content)`
  ${(props) =>
    props.theme?.isMobile &&
    css`
      ${Layout.Padded} {
        ${spacing.stack(SPACING_SIZE.L)}
      }
      ${Grid} {
        display: flex;
        $(Heading) {
          ${typography.size(FONT_SIZE.XS * 3)}
        }
        gap: ${spacing.value(SPACING_SIZE.L)};
      }
    `}
`;

export default function Processing() {
  const subscriptionChangeRequestId = useSelector(checkoutPageSCRIdSelector);
  const subRoute = useSelector(checkoutSubRouteSelector);
  useEffect(() => {
    dispatch({
      id: CHECKOUT_FIRST_PAYMENT_PROCESSING_PAGE_MOUNTED,
      payload: {
        subscriptionChangeRequestId,
        successUrl: checkoutSuccessRoute({
          subscriptionChangeRequestId,
          subRoute,
        }),
        failureUrl: checkoutFailedRoute({
          subscriptionChangeRequestId,
          subRoute,
        }),
      },
    });
  }, []);

  return (
    <Layout independentScroll>
      <Layout.Header>
        <Navigation title={<PublisherData />} hideLinks hideAppBanner />
      </Layout.Header>
      <Layout.LeftSidebar>
        <Sidebar currentStep={CHECKOUT_STEPS.CONFIRMATION} />
      </Layout.LeftSidebar>
      <Content>
        <Layout.Padded>
          <Layout.Content>
            <Layout.Padded>
              <Grid
                areas={({ repeat }) => `
            "${repeat(5, 'form')} ${repeat(3, 'summary')} ."
          `}
              >
                <Grid.Area $id="form">
                  <Heading>
                    <FormattedMessageWithValidation id="checkout_bankCard_processing_title" />
                  </Heading>
                  <LoaderWrap>
                    <Loader />
                  </LoaderWrap>
                  <Body1>
                    <FormattedMessageWithValidation id="checkout_bankCard_processing_text" />
                  </Body1>
                </Grid.Area>
              </Grid>
            </Layout.Padded>
          </Layout.Content>
        </Layout.Padded>
      </Content>
    </Layout>
  );
}
