import styled from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import {
  FlatButton,
  TextButton,
} from 'design-system/components/Button/presets';
import { dispatch, dispatchMany } from 'reffects';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { useFeatureForPublisherCountrySelector } from '../../../../utils/featuresFlags/selectors';
import { OPEN_DIALOG } from '../../../../events/dialogs';
import { GENERIC_CONFIRMATION_MODAL } from '../../../../partials/modals/generic/constants';
import { ADD_CREDIT_CARD, DELETE_CREDIT_CARD } from './events';
import { TRACK_CLICK } from '../../../../partials/Tracking/events';
import { SavedBankCard } from '../../../../partials/SavedBankCard';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.value(SPACING_SIZE.S)};

  ${FlatButton} {
    padding: 8px 0;
  }
`;

const Actions = styled.div`
  display: flex;
  gap: ${spacing.value(SPACING_SIZE.M)};

  ${TextButton} {
    padding: 0;
  }
`;

function CreditCard({ cardType, tokenizedCardNumber }) {
  const DELETE_CREDIT_CARD_4030 = useFeatureForPublisherCountrySelector(
    'DELETE_CREDIT_CARD_4030'
  );
  const UPDATE_CREDIT_CARD_4043 = useFeatureForPublisherCountrySelector(
    'UPDATE_CREDIT_CARD_4043'
  );
  return (
    <Wrapper>
      <SavedBankCard
        cardType={cardType}
        tokenizedCardNumber={tokenizedCardNumber}
      />
      <Actions>
        {UPDATE_CREDIT_CARD_4043 && (
          <TextButton
            onClick={() => {
              dispatch(ADD_CREDIT_CARD);
            }}
          >
            <FormattedMessageWithValidation id="subscription_payment_creditCard_changeCard" />
          </TextButton>
        )}
        {DELETE_CREDIT_CARD_4030 && (
          <FlatButton
            onClick={() =>
              dispatchMany([
                {
                  id: TRACK_CLICK,
                  payload: {
                    eventName: 'request-delete-credit-card',
                    sectionName: 'my-subscription',
                  },
                },
                {
                  id: OPEN_DIALOG,
                  payload: {
                    id: GENERIC_CONFIRMATION_MODAL,
                    parameters: {
                      messages: {
                        title: {
                          id: 'subscription_payment_creditCard_delete_modal_title',
                        },
                        body: {
                          id: 'subscription_payment_creditCard_delete_modal_text',
                        },
                      },
                      actions: {
                        confirm: DELETE_CREDIT_CARD,
                      },
                    },
                  },
                },
              ])
            }
          >
            <FormattedMessageWithValidation id="subscription_payment_creditCard_delete" />
          </FlatButton>
        )}
      </Actions>
    </Wrapper>
  );
}

export default CreditCard;
