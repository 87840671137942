import styled from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import Group from 'design-system/components/Group';
import CreditCard from './CreditCard';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';

const Content = styled.div`
  > ${Body1} {
    display: block;
    ${spacing.stack(SPACING_SIZE.L)}
  }
`;

function WithCreditCard({ creditCard: { lastDigits, creditCardType } }) {
  return (
    <Group
      title={
        <FormattedMessageWithValidation id="subscription_payment_information_title" />
      }
    >
      <Content>
        <CreditCard
          cardType={creditCardType}
          tokenizedCardNumber={lastDigits}
        />
      </Content>
    </Group>
  );
}

export default WithCreditCard;
