import { publishedPropertiesSelector } from '../../../selectors/propertiesSummary';
import {
  isAgencySelector,
  publisherCountrySelector,
  publisherSubscriptionPlanIsFreeSelector,
} from '../../../selectors/publisher';
import { COUNTRY_CODE } from '../../../constants/country';

export function showSubscriptionBadgeSelector(state) {
  const availableCountries = [
    COUNTRY_CODE.ARGENTINA,
    COUNTRY_CODE.PERU,
    COUNTRY_CODE.CHILE,
    COUNTRY_CODE.COLOMBIA,
    COUNTRY_CODE.MEXICO,
  ];
  const isAgency = isAgencySelector(state);
  const publishedProperties = publishedPropertiesSelector(state);
  const isFree = publisherSubscriptionPlanIsFreeSelector(state);
  const countryCode = publisherCountrySelector(state);
  if (countryCode === COUNTRY_CODE.ARGENTINA) {
    return isAgency && isFree;
  }
  return (
    isAgency &&
    availableCountries.includes(countryCode) &&
    publishedProperties <= 10 &&
    isFree
  );
}
