import styled, { css } from 'styled-components';
import { divider, DIVIDER_SIZE } from 'design-system/styles/divider';
import Logo from 'design-system/components/Logo/Logo';
import {
  color,
  COLOR_PALETTE,
  COLOR_SEMANTIC,
} from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { layout } from 'design-system/tokens/layout';
import TabLink from './TabLink';

export const NAVIGATION_BAR_VARIANT = {
  LIGHT: Symbol('NAVIGATION_BAR_VARIANTS_LIGHT'),
  DARK: Symbol('NAVIGATION_BAR_VARIANTS_DARK'),
};

const modDefaultVariant = () => css`
  ${color.background(COLOR_SEMANTIC.PAPER)}
`;

const modDarkVariant = () => css`
  ${color.background(COLOR_PALETTE.SECONDARY_BASE)}
  ${color.text(COLOR_PALETTE.NEUTRAL_A00)}
`;

const modVariant = (variantName) =>
  ({
    [NAVIGATION_BAR_VARIANT.LIGHT]: modDefaultVariant,
    [NAVIGATION_BAR_VARIANT.DARK]: modDarkVariant,
  }[variantName]);

export const Wrapper = styled.nav`
  width: 100%;

  ${divider.bottom(DIVIDER_SIZE.THIN)}

  ${Logo} {
    ${spacing.inline(SPACING_SIZE.XXL)}
  }

  ${({ variant }) => modVariant(variant)}
`;

const Controls = styled.div`
  display: flex;
  align-items: center;
  ${(props) =>
    props.theme?.isMobile &&
    css`
      display: none;
    `}

  > * {
    ${spacing.inline(SPACING_SIZE.M)}
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: 0 auto;

  ${(props) =>
    !props.theme?.isMobile &&
    css`
      min-width: ${layout.minWidth}px;
      max-width: ${layout.maxWidth}px;
    `}

  ${(props) =>
    props.theme?.isMobile &&
    css`
      > a:link {
        //Disable home("/") link through css as props.theme.isMobile is only available on checkout pages, where we want to disable it
        pointer-events: none;
      }
    `}

  ${spacing.insetSquish(SPACING_SIZE.XL)}
`;

function NavigationBar({ logo, children, ...rest }) {
  if (!rest.variant) {
    // eslint-disable-next-line no-param-reassign
    rest.variant = NAVIGATION_BAR_VARIANT.LIGHT;
  }
  return (
    <Wrapper {...rest}>
      <Content>
        {logo}
        <Controls>{children}</Controls>
      </Content>
    </Wrapper>
  );
}

NavigationBar.TabLink = TabLink;

export default styled(NavigationBar)``;
