import Illustration from 'design-system/components/Illustration/Illustration';
import { Subtitle3 } from 'design-system/components/Typography/presets/Subtitle3';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { FONT_WEIGHT } from 'design-system/styles/typography';
import styled from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import FormattedMessageWithValidation from './FormattedMessageWithValidation/FormattedMessageWithValidation';

const Card = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: ${spacing.value(SPACING_SIZE.L)};
`;

const Info = styled.div`
  > ${Subtitle3} {
    display: block;
    ${spacing.stack(SPACING_SIZE.S)}
  }
`;

function cardEntry(illustration, tag) {
  return { illustration, tag };
}

const cards = {
  visa: cardEntry('visaCreditCard', 'subscription_payment_creditCard_visa'),
  debvisa: cardEntry('visaCreditCard', 'subscription_payment_creditCard_visa'),
  mc: cardEntry(
    'masterCardCreditCard',
    'subscription_payment_creditCard_mastercard'
  ),
  master: cardEntry(
    'masterCardCreditCard',
    'subscription_payment_creditCard_mastercard'
  ),
  debmaster: cardEntry(
    'masterCardCreditCard',
    'subscription_payment_creditCard_mastercard'
  ),
  amex: cardEntry(
    'maestroCardCreditCard',
    'subscription_payment_creditCard_maestro'
  ),
  debamex: cardEntry(
    'maestroCardCreditCard',
    'subscription_payment_creditCard_maestro'
  ),
};

export function SavedBankCard({ cardType, tokenizedCardNumber, ...props }) {
  const { illustration, tag } = cards[cardType];
  return (
    <Card {...props}>
      {illustration && <Illustration name={illustration} />}
      <Info>
        {tag && (
          <Subtitle3>
            <FormattedMessageWithValidation id={tag} />
          </Subtitle3>
        )}
        <Body1 $weight={FONT_WEIGHT.LIGHT}>
          **** **** **** {tokenizedCardNumber}
        </Body1>
      </Info>
    </Card>
  );
}
