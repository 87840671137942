import { useSelector } from 'reffects-store';
import { ThemeProvider } from 'styled-components';
import { Route, Routes } from 'react-router-dom';
import { isMobileSelector } from '../selectors';
import {
  SELF_SERVICE_CREATE,
  SELF_SERVICE_ERROR,
  SUBSCRIPTION_OFFERS,
} from '../../constants/routes';
import { PrivateRoute, PublicRoute } from './index';
import { NotFound } from '../../pages';
import OffersPage from '../../pages/Offers/OffersPage';
import SelfServiceCreate from '../../pages/SelfService/SelfServiceCreate';
import SelfServiceError from '../../pages/SelfService/SelfServiceError';

function RoutesList() {
  return (
    <Routes>
      <Route path="*" element={<PrivateRoute as={NotFound} />} />
      <Route
        path={SUBSCRIPTION_OFFERS}
        element={
          <PublicRoute
            as={OffersPage}
            pageViewName="self-service-offers-page"
            pageViewPayloadSelector={({ publisherId, countryCode }) => ({
              publisherId,
              countryCode,
            })}
            disableInterstitial
          />
        }
      />
      <Route
        path={SELF_SERVICE_CREATE}
        element={
          <PublicRoute
            as={SelfServiceCreate}
            pageViewName="self-service-create"
            pageViewPayloadSelector={({ origin, cta }) => ({
              origin,
              cta,
            })}
            disableInterstitial
          />
        }
      />
      <Route
        path={SELF_SERVICE_ERROR}
        element={
          <PublicRoute
            as={SelfServiceError}
            pageViewName="self-service-error"
            pageViewPayloadSelector={({ origin, cta }) => ({
              origin,
              cta,
            })}
            disableInterstitial
          />
        }
      />
    </Routes>
  );
}

export default function ResponsiveRoutes() {
  const isMobile = useSelector(isMobileSelector);
  return (
    <ThemeProvider theme={{ isMobile }}>
      <RoutesList />
    </ThemeProvider>
  );
}
