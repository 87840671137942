import { noop } from 'design-system/utils';
import InputSelectWithHtmlValidation from './InputSelectWithHtmlValidation';

export default function MaxBoostablePropertiesSelect({
  options,
  selectedOption,
  onChange,
  isDisabled,
  required,
}) {
  return (
    <InputSelectWithHtmlValidation
      label="Boostable Properties"
      value={selectedOption}
      disabled={isDisabled}
      choices={options.map((maxBoostableProperty) => ({
        label: maxBoostableProperty,
        value: maxBoostableProperty,
      }))}
      onSelect={onChange}
      onChange={noop}
      required={required}
    />
  );
}
