import styled, { css } from 'styled-components';
import { scrollbar } from 'design-system/styles/scrollbar';

const Wrapper = styled.div`
  --scrollbar-width: 15px;

  overflow-y: auto;
  position: relative;
  max-height: ${({ maxHeight }) => maxHeight || 250}px;

  > div {
    max-height: inherit;
    overflow-y: auto;
    padding-bottom: 1px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    ${scrollbar}
  }

  ${({ withBottomShadow }) =>
    withBottomShadow &&
    css`
      ::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: var(--scrollbar-width);
        height: 48px;
        background: linear-gradient(
          0deg,
          #ffffff 0%,
          rgba(255, 255, 255, 0) 100%
        );
      }
    `}
`;

function ScrollableArea({ children, ...rest }) {
  return (
    <Wrapper {...rest}>
      <div>{children}</div>
    </Wrapper>
  );
}

export default styled(ScrollableArea)``;
