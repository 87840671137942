import { dispatch, dispatchMany } from 'reffects';
import { useSelector } from 'reffects-store';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { useCallback } from 'react';
import { debounce } from 'lodash';
import Checkbox from 'design-system/components/Checkbox/Checkbox';
import InputSelect from 'design-system/components/InputSelect';
import InputNumber, {
  INPUT_NUMBER_TYPE,
} from 'design-system/components/InputNumber';
import InputText from 'design-system/components/InputText';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import {
  FONT_SIZE,
  FONT_WEIGHT,
  typography,
} from 'design-system/styles/typography';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import {
  ItemFullWidth,
  ItemTwoColumns,
} from '../../../../../../../partials/SimpleFormLayout';
import { RequestTypeSelect } from './RequestTypeSelect';
import { PublishesInThailandPropertyCheckbox } from '../partials/PublishesInThailandPropertyCheckbox';
import {
  SUBSCRIPTION_CHANGE_REQUEST_MODAL_ADDITIONAL_MONTH_CHANGED,
  SUBSCRIPTION_CHANGE_REQUEST_MODAL_BASE_CUSTOM_DISCOUNT_CHANGED,
  SUBSCRIPTION_CHANGE_REQUEST_MODAL_BASE_FINAL_PRICE_CHANGED,
  SUBSCRIPTION_CHANGE_REQUEST_MODAL_BILLING_CYCLE_CHANGED,
  SUBSCRIPTION_CHANGE_REQUEST_MODAL_BILLING_DETAIL_REQUESTED,
  SUBSCRIPTION_CHANGE_REQUEST_MODAL_EXTRA_USERS_CUSTOM_DISCOUNT_CHANGED,
  SUBSCRIPTION_CHANGE_REQUEST_MODAL_EXTRA_USERS_FINAL_PRICE_CHANGED,
  SUBSCRIPTION_CHANGE_REQUEST_MODAL_EXTRA_USERS_SELECTED,
  SUBSCRIPTION_CHANGE_REQUEST_MODAL_MAX_BOOSTABLE_PROPERTIES_SELECTED,
  SUBSCRIPTION_CHANGE_REQUEST_MODAL_MAX_SUPERBOOSTABLE_PROPERTIES_SELECTED,
  SUBSCRIPTION_CHANGE_REQUEST_MODAL_PAYMENT_METHOD_SELECTED,
  SUBSCRIPTION_CHANGE_REQUEST_MODAL_PLAN_SELECTED,
  SUBSCRIPTION_CHANGE_REQUEST_MODAL_PUBLISHER_USERS_REQUESTED,
  SUBSCRIPTION_CHANGE_REQUEST_MODAL_PUBLISHES_IN_THAILAND_PROPERTY_CHANGED,
  SUBSCRIPTION_CHANGE_REQUEST_MODAL_SUPERBOOST_CUSTOM_DISCOUNT_CHANGED,
  SUBSCRIPTION_CHANGE_REQUEST_MODAL_SUPERBOOST_FINAL_PRICE_CHANGED,
  SUBSCRIPTION_CHANGE_REQUEST_MODAL_TYPE_OF_CLIENT_SELECTED,
  SUBSCRIPTION_CHANGE_REQUEST_MODAL_WASI_PLAN_CUSTOM_DISCOUNT_CHANGED,
  SUBSCRIPTION_CHANGE_REQUEST_MODAL_WASI_PLAN_FINAL_PRICE_CHANGED,
  SUBSCRIPTION_CHANGE_REQUEST_MODAL_WASI_PLAN_SELECTED,
  SUBSCRIPTION_CHANGE_REQUEST_MODAL_WASI_USER_SELECTED,
} from './events';
import { SUBSCRIPTION_CHANGE_TYPE } from '../../../../../../constants/subscription';
import { formatDate } from '../../../../../../../partials/FormattedDate/CustomFormattedDate';
import Summary from '../partials/Summary';
import {
  availablePaymentMethodsSelector,
  availableTypeOfClientSelector,
  availableUsersForWasiAccountSelector,
  canUseAdditionalMonthCheckboxSelector,
  checkErrorOnMaxSuperboostableSelector,
  disableBaseSubscriptionCustomDiscountFieldsSelector,
  disabledAdditionalMonthSelector,
  disableExtraUsersDiscountFieldsSelector,
  disableSuperboostCustomDiscountFieldsSelector,
  disableWasiPlanCustomDiscountFieldsSelector,
  extraUsersCustomDiscountSelector,
  extraUsersFinalPriceSelector,
  extraUsersSelector,
  generateUpsellCustomDiscountSectionTitleSelector,
  hasWasiAccountErrorSelector,
  isAutorenewalUpsellCurrentCycleSelector,
  isUpsellNewCycleOrCurrentCycleSelector,
  maxBoostablePropertiesSelector,
  plansByMaxBoostablePropertiesSelector,
  publishesInThailandPropertyInputValueSelector,
  purchaseWasiSubscriptionEnabledSelector,
  selectedAdditionalMonthSelector,
  selectedBillingCycleSelector,
  selectedBoostingLimitSelector,
  selectedPaymentMethodSelector,
  selectedPlanIdSelector,
  selectedPublisherCountryCodeSelector,
  selectedPublisherIsFreeSelector,
  selectedPublisherIsFromLATAM,
  selectedPublisherIsInGracePeriodSelector,
  selectedPublisherIsLoadedSelector,
  selectedPublisherNextBillingDateSelector,
  selectedSuperboostingLimitSelector,
  selectedTypeOfClientSelector,
  showPublishesInThailandPropertySelector,
  subscriptionChangeRequestTypeSelector,
  subscriptionCustomDiscountSelector,
  subscriptionFinalPriceSelector,
  summarySelector,
  superboostCustomDiscountSelector,
  superboostFinalPriceSelector,
  wasiAccountEmailSelector,
  wasiPlanCustomDiscountSelector,
  wasiPlanFinalPriceSelector,
  wasiPlanIdSelector,
  wasiPlanSelector,
} from './selectors';
import { billingCycleOptions } from '../../../../../../utils/billingCycleOptions';
import { shouldShowNewSubscriptionDates } from './functions/shouldShowNewSubscriptionDates';
import PlanToSubscribe from '../../../../../partials/PlanToSubscribe/PlanToSubscribe';
import { MaxSuperboostablePropertiesSelect } from './MaxSuperboostablePropertiesSelect';
import MaxBoostablePropertiesSelect from '../../../../../partials/MaxBoostablePropertiesSelect';
import { isMexico } from '../../../../../../../utils/countries';
import TypeOfClientSelect from '../../../../../partials/TypeOfClientSelect/TypeOfClientSelect';
import { countryHasSuperboostEnabled } from '../../../../../../../selectors/config';
import { getSymbolByCountryCode } from '../../../../../../../utils/currencies';
import {
  useFeatureForCountrySelector,
  useFeatureSelector,
} from '../../../../../../../utils/featuresFlags/selectors';
import WasiPlanSelect from '../../../../../partials/WasiPlanSelect/WasiPlanSelect';
import ExtraUsersInput from '../../../../../partials/ExtraUsersInput';

const DEBOUNCE_INPUT_CHANGE_MILIS = 500;

const helperTextByChangeType = {
  [SUBSCRIPTION_CHANGE_TYPE.UPSELL_NEXT_CYCLE]:
    'The change on the subscription will start at the end of the current cycle',
  [SUBSCRIPTION_CHANGE_TYPE.UPSELL_NEW_CYCLE]:
    'Upsell starts the day the client’s payment is processed, with a new billing cycle.',
  [SUBSCRIPTION_CHANGE_TYPE.UPSELL_CURRENT_CYCLE]:
    'Upsell starts the day the client’s payment is processed, but runs within the current subscription billing cycle.',
};

const HelperText = styled.div`
  ${spacing.inset(SPACING_SIZE.S)}
  ${color.background(COLOR_PALETTE.PUMPKIN_A10)}
    ${typography.size(FONT_SIZE.S)}
    ${color.text(COLOR_PALETTE.NEUTRAL_BASE)}
`;

const TwoColumnsHalfSpace = styled.div`
  display: flex;
  flex-direction: row;

  > *:nth-child(1) {
    display: flex;
    align-items: end;

    label {
      width: 100%;
    }
  }

  > *:nth-child(2) {
    display: flex;
    flex-direction: column;
    font-size: ${FONT_SIZE.S}px;
    ${typography.weight(FONT_WEIGHT.MEDIUM)}
    gap: ${spacing.value(SPACING_SIZE.S)};
  }
`;

const ItemColumnTitle = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${spacing.value(SPACING_SIZE.S)};
`;

const CheckboxLabel = styled(Checkbox.Label)`
  display: inline-flex;
`;

export function RequestSubscriptionChangeForm({
  subscriptionChangeTypeValueForSelect,
}) {
  const UPSELL_WITH_CARD_4183 = useFeatureSelector('UPSELL_WITH_CARD_4183');
  const subscriptionChangeType = useSelector(
    subscriptionChangeRequestTypeSelector
  );
  const helperText = helperTextByChangeType[subscriptionChangeType];
  const intl = useIntl();
  const publisherIsLoaded = useSelector(selectedPublisherIsLoadedSelector);
  const currentNextBillingDate = useSelector(
    selectedPublisherNextBillingDateSelector
  );
  const countryCode = useSelector(selectedPublisherCountryCodeSelector);
  const subscriptionRequestChangeSummary = useSelector(summarySelector);
  const selectedBillingCycle = useSelector(selectedBillingCycleSelector);
  const selectedPlanId = useSelector(selectedPlanIdSelector);
  const selectedTypeOfClient = useSelector(selectedTypeOfClientSelector);
  const availableTypesOfClient = useSelector(availableTypeOfClientSelector);
  const selectedPaymentMethod = useSelector(selectedPaymentMethodSelector);
  const wasiPlansToSubscribe = useSelector(wasiPlanSelector);
  const selectedPublisherIsFree = useSelector(selectedPublisherIsFreeSelector);
  const selectedPublisherIsInGracePeriod = useSelector(
    selectedPublisherIsInGracePeriodSelector
  );
  const showPublishesInThailandPropertyCheckbox = useSelector(
    showPublishesInThailandPropertySelector
  );
  const publishesInThailandPropertyValue = useSelector(
    publishesInThailandPropertyInputValueSelector
  );
  const availablePaymentMethods = useSelector(availablePaymentMethodsSelector);
  const maxBoostablePropertiesOptions = useSelector(
    maxBoostablePropertiesSelector
  );
  const maxBoostableSelectedOption = useSelector(selectedBoostingLimitSelector);

  const SUPERBOOST_ALL_COUNTRIES_3119 = useFeatureForCountrySelector(
    'SUPERBOOST_ALL_COUNTRIES_3119',
    countryCode
  );
  const superboostEnabled = countryHasSuperboostEnabled(countryCode);
  const selectedMaxSuperboostable = useSelector(
    selectedSuperboostingLimitSelector
  );
  const checkErrorOnMaxSuperboostable = useSelector(
    checkErrorOnMaxSuperboostableSelector
  );

  const billingCycleChoices = billingCycleOptions();
  const showNewSubscriptionDates = shouldShowNewSubscriptionDates(
    subscriptionChangeType
  );
  const mexicoCountrySelected = isMexico(countryCode);
  const purchaseWasiSubscriptionEnabled = useSelector(
    purchaseWasiSubscriptionEnabledSelector
  );

  const selectedWasiPlanId = useSelector(wasiPlanIdSelector);
  const selectedExtraUsers = useSelector(extraUsersSelector);
  const availableUsersForWasiAccount = useSelector(
    availableUsersForWasiAccountSelector
  );
  const selectedUserForWasiAccount = useSelector(wasiAccountEmailSelector);
  const wasiAccountHasError = useSelector(hasWasiAccountErrorSelector);

  const superboostDiscount = useSelector(superboostCustomDiscountSelector);
  const subscriptionDiscount = useSelector(subscriptionCustomDiscountSelector);
  const wasiPlanDiscount = useSelector(wasiPlanCustomDiscountSelector);
  const extraUsersDiscount = useSelector(extraUsersCustomDiscountSelector);

  const superboostFinalPrice = useSelector(superboostFinalPriceSelector);
  const subscriptionFinalPrice = useSelector(subscriptionFinalPriceSelector);
  const wasiPlanFinalPrice = useSelector(wasiPlanFinalPriceSelector);
  const extraUsersFinalPrice = useSelector(extraUsersFinalPriceSelector);

  const debounceBaseFinalPriceChanged = useCallback(
    debounce(onBaseFinalPriceChanged, DEBOUNCE_INPUT_CHANGE_MILIS),
    [onBaseFinalPriceChanged]
  );
  const debounceBaseCustomDiscountChanged = useCallback(
    debounce(onBaseCustomDiscountChanged, DEBOUNCE_INPUT_CHANGE_MILIS),
    [onBaseCustomDiscountChanged]
  );
  const debounceSuperboostFinalPriceChanged = useCallback(
    debounce(onSuperboostFinalPriceChanged, DEBOUNCE_INPUT_CHANGE_MILIS),
    [onSuperboostFinalPriceChanged]
  );
  const debounceSuperboostCustomDiscountChanged = useCallback(
    debounce(onSuperboostCustomDiscountChanged, DEBOUNCE_INPUT_CHANGE_MILIS),
    [onSuperboostCustomDiscountChanged]
  );
  const debounceWasiPlanFinalPriceChanged = useCallback(
    debounce(onWasiPlanFinalPriceChanged, DEBOUNCE_INPUT_CHANGE_MILIS),
    [onWasiPlanFinalPriceChanged]
  );
  const debounceWasiPlanCustomDiscountChanged = useCallback(
    debounce(onWasiPlanCustomDiscountChanged, DEBOUNCE_INPUT_CHANGE_MILIS),
    [onWasiPlanCustomDiscountChanged]
  );
  const debounceExtraUsersChanged = useCallback(
    debounce(onExtraUsersChange, DEBOUNCE_INPUT_CHANGE_MILIS),
    [onExtraUsersChange]
  );
  const debounceExtraUsersFinalPriceChanged = useCallback(
    debounce(onExtraUsersFinalPriceChanged, DEBOUNCE_INPUT_CHANGE_MILIS),
    [onExtraUsersFinalPriceChanged]
  );
  const debounceExtraUsersCustomDiscountChanged = useCallback(
    debounce(onExtraUsersCustomDiscountChanged, DEBOUNCE_INPUT_CHANGE_MILIS),
    [onExtraUsersCustomDiscountChanged]
  );

  const disableBaseSubscriptionCustomDiscountFields = useSelector(
    disableBaseSubscriptionCustomDiscountFieldsSelector
  );
  const disableSuperboostCustomDiscountFields = useSelector(
    disableSuperboostCustomDiscountFieldsSelector
  );
  const disableWasiPlanCustomDiscountFields = useSelector(
    disableWasiPlanCustomDiscountFieldsSelector
  );
  const disableExtraUsersCustomDiscountFields = useSelector(
    disableExtraUsersDiscountFieldsSelector
  );

  const disabledAdditionalMonth = useSelector(disabledAdditionalMonthSelector);
  const additionalMonth = useSelector(selectedAdditionalMonthSelector);
  const isSelectedPublisherFromLatam = useSelector(
    selectedPublisherIsFromLATAM
  );
  const canUseAdditionalMonthCheckbox = useSelector(
    canUseAdditionalMonthCheckboxSelector
  );

  const isUpsellNewCycleOrCurrentCycle = useSelector(
    isUpsellNewCycleOrCurrentCycleSelector
  );
  const isAutorenewalUpsellCurrentCycle = useSelector(
    isAutorenewalUpsellCurrentCycleSelector
  );

  const generateUpsellCustomDiscountSectionTitle = useSelector(
    generateUpsellCustomDiscountSectionTitleSelector
  );

  const hasSetAFixedPriceOrCustomDiscount =
    superboostDiscount ||
    subscriptionDiscount ||
    superboostFinalPrice ||
    subscriptionFinalPrice;

  let plansToSubscribe = useSelector(plansByMaxBoostablePropertiesSelector);
  plansToSubscribe = plansToSubscribe.filter(({ active }) => active === true);

  return (
    <>
      {subscriptionChangeType === '' ? (
        <ItemFullWidth>
          <RequestTypeSelect
            subscriptionChangeType={subscriptionChangeTypeValueForSelect}
            canRequestUpsellNewCycle={!mexicoCountrySelected}
            selectedPublisherIsFree={selectedPublisherIsFree}
            disabled={!publisherIsLoaded}
          />
        </ItemFullWidth>
      ) : (
        <>
          <ItemFullWidth>
            <RequestTypeSelect
              subscriptionChangeType={subscriptionChangeTypeValueForSelect}
              canRequestUpsellNewCycle={!mexicoCountrySelected}
              selectedPublisherIsFree={selectedPublisherIsFree}
              disabled={!publisherIsLoaded}
            />
          </ItemFullWidth>
          {helperText && (
            <ItemFullWidth>
              <HelperText>{helperText}</HelperText>
            </ItemFullWidth>
          )}
          <ItemFullWidth>
            {showPublishesInThailandPropertyCheckbox && (
              <PublishesInThailandPropertyCheckbox
                onChange={(event) =>
                  dispatch({
                    id: SUBSCRIPTION_CHANGE_REQUEST_MODAL_PUBLISHES_IN_THAILAND_PROPERTY_CHANGED,
                    payload: { value: event.target.checked },
                  })
                }
                checked={publishesInThailandPropertyValue}
              />
            )}
          </ItemFullWidth>
          {mexicoCountrySelected && (
            <ItemTwoColumns>
              <TypeOfClientSelect
                value={selectedTypeOfClient}
                onChange={onTypeOfClientSelected}
                availableTypes={availableTypesOfClient}
              />
            </ItemTwoColumns>
          )}
          {!mexicoCountrySelected && (
            <ItemTwoColumns>
              <PlanToSubscribe
                label="Boosting level"
                disabled={
                  subscriptionChangeType === SUBSCRIPTION_CHANGE_TYPE.RENEW ||
                  !publisherIsLoaded
                }
                value={selectedPlanId}
                onChange={onPlanSelected}
                plansByMaxBoostableProperties={plansToSubscribe}
                countryCode={countryCode}
                required
              />
            </ItemTwoColumns>
          )}
          <ItemTwoColumns>
            <InputSelect
              label="Billing cycle"
              value={selectedBillingCycle}
              disabled={
                subscriptionChangeType ===
                SUBSCRIPTION_CHANGE_TYPE.UPSELL_CURRENT_CYCLE
              }
              choices={billingCycleChoices.map(([duration, durationLabel]) => ({
                label: durationLabel,
                value: duration,
              }))}
              onSelect={changeSubscriptionRenewalBillingCycle}
              required
            />
          </ItemTwoColumns>
          {isSelectedPublisherFromLatam &&
            subscriptionChangeType !==
              SUBSCRIPTION_CHANGE_TYPE.UPSELL_CURRENT_CYCLE && (
              <>
                {canUseAdditionalMonthCheckbox && (
                  <>
                    <ItemTwoColumns />
                    <ItemTwoColumns>
                      <CheckboxLabel>
                        <Checkbox
                          name="additionalMonth"
                          checked={additionalMonth ?? false}
                          onChange={(event) =>
                            dispatch({
                              id: SUBSCRIPTION_CHANGE_REQUEST_MODAL_ADDITIONAL_MONTH_CHANGED,
                              payload: { value: event.target.checked },
                            })
                          }
                          disabled={disabledAdditionalMonth}
                        />
                        <Body1>Add 1 month for free</Body1>
                      </CheckboxLabel>
                    </ItemTwoColumns>
                  </>
                )}
              </>
            )}
          <TwoColumnsHalfSpace>
            <ItemTwoColumns>
              <MaxBoostablePropertiesSelect
                options={maxBoostablePropertiesOptions}
                selectedOption={maxBoostableSelectedOption}
                onChange={onMaxBoostablePropertiesSelected}
                required
              />
            </ItemTwoColumns>
            <ItemTwoColumns>
              {UPSELL_WITH_CARD_4183 &&
                generateUpsellCustomDiscountSectionTitle}
              <ItemColumnTitle>
                <InputNumber
                  label="Final Price"
                  value={subscriptionFinalPrice}
                  unit={getSymbolByCountryCode(countryCode)}
                  type={INPUT_NUMBER_TYPE.DECIMAL}
                  maxDecimals={12}
                  onChange={(e) => {
                    debounceBaseFinalPriceChanged(e);
                  }}
                  disabled={disableBaseSubscriptionCustomDiscountFields}
                />
                <InputNumber
                  label="Custom discount"
                  value={subscriptionDiscount ?? ''}
                  unit="%"
                  type={INPUT_NUMBER_TYPE.DECIMAL}
                  maxDecimals={12}
                  onChange={(e) => {
                    debounceBaseCustomDiscountChanged(e);
                  }}
                  disabled={
                    disableBaseSubscriptionCustomDiscountFields ||
                    (UPSELL_WITH_CARD_4183 && isAutorenewalUpsellCurrentCycle)
                  }
                />
              </ItemColumnTitle>
            </ItemTwoColumns>
          </TwoColumnsHalfSpace>
          {(SUPERBOOST_ALL_COUNTRIES_3119 || superboostEnabled) && (
            <TwoColumnsHalfSpace>
              <ItemTwoColumns>
                <MaxSuperboostablePropertiesSelect
                  selectedMaxSuperboostable={selectedMaxSuperboostable}
                  hasError={checkErrorOnMaxSuperboostable}
                  errorMessage={
                    checkErrorOnMaxSuperboostable
                      ? `The amount can't be greater than boostable properties`
                      : ''
                  }
                  onInputChange={onMaxSuperboostableChange}
                />
              </ItemTwoColumns>
              <ItemTwoColumns>
                {UPSELL_WITH_CARD_4183 &&
                  generateUpsellCustomDiscountSectionTitle}
                <ItemColumnTitle>
                  <InputNumber
                    label="Final Price"
                    value={superboostFinalPrice ?? ''}
                    unit={getSymbolByCountryCode(countryCode)}
                    type={INPUT_NUMBER_TYPE.DECIMAL}
                    maxDecimals={12}
                    onChange={(e) => {
                      debounceSuperboostFinalPriceChanged(e);
                    }}
                    disabled={disableSuperboostCustomDiscountFields}
                  />
                  <InputNumber
                    label="Custom discount"
                    value={superboostDiscount ?? ''}
                    unit="%"
                    type={INPUT_NUMBER_TYPE.DECIMAL}
                    maxDecimals={12}
                    onChange={(e) => {
                      debounceSuperboostCustomDiscountChanged(e);
                    }}
                    disabled={
                      disableSuperboostCustomDiscountFields ||
                      (UPSELL_WITH_CARD_4183 && isAutorenewalUpsellCurrentCycle)
                    }
                  />
                </ItemColumnTitle>
              </ItemTwoColumns>
            </TwoColumnsHalfSpace>
          )}
          {subscriptionChangeType !==
            SUBSCRIPTION_CHANGE_TYPE.UPSELL_NEW_CYCLE && (
            <ItemTwoColumns>
              <InputText
                value={formatDate(intl, currentNextBillingDate) ?? ''}
                label="Current next billing date"
                disabled
                readOnly
              />
            </ItemTwoColumns>
          )}
          <ItemTwoColumns>
            <InputSelect
              label="Payment method"
              value={selectedPaymentMethod}
              choices={availablePaymentMethods.map(({ id, label }) => ({
                label,
                value: id,
              }))}
              onSelect={({ value }) =>
                dispatchMany([
                  {
                    id: SUBSCRIPTION_CHANGE_REQUEST_MODAL_PAYMENT_METHOD_SELECTED,
                    payload: {
                      paymentMethod: value,
                    },
                  },
                  {
                    id: SUBSCRIPTION_CHANGE_REQUEST_MODAL_BILLING_DETAIL_REQUESTED,
                  },
                ])
              }
              required
            />
          </ItemTwoColumns>
          {subscriptionRequestChangeSummary && showNewSubscriptionDates && (
            <NewSubscriptionDates summary={subscriptionRequestChangeSummary} />
          )}
          {purchaseWasiSubscriptionEnabled && (
            <>
              <TwoColumnsHalfSpace>
                <ItemTwoColumns>
                  <WasiPlanSelect
                    label="Wasi Plan"
                    value={selectedWasiPlanId}
                    onChange={onWasiPlanSelected}
                    plans={wasiPlansToSubscribe}
                    disabled={false}
                  />
                </ItemTwoColumns>
                <ItemTwoColumns>
                  <ItemColumnTitle>
                    <InputNumber
                      label="Final Price"
                      value={wasiPlanFinalPrice ?? ''}
                      unit={getSymbolByCountryCode(countryCode)}
                      type={INPUT_NUMBER_TYPE.DECIMAL}
                      maxDecimals={12}
                      onChange={(e) => {
                        debounceWasiPlanFinalPriceChanged(e);
                      }}
                      disabled={disableWasiPlanCustomDiscountFields}
                    />
                    <InputNumber
                      label="Custom discount"
                      value={wasiPlanDiscount ?? ''}
                      unit="%"
                      type={INPUT_NUMBER_TYPE.DECIMAL}
                      maxDecimals={12}
                      onChange={(e) => {
                        debounceWasiPlanCustomDiscountChanged(e);
                      }}
                      disabled={disableWasiPlanCustomDiscountFields}
                    />
                  </ItemColumnTitle>
                </ItemTwoColumns>
              </TwoColumnsHalfSpace>
              {selectedWasiPlanId && (
                <TwoColumnsHalfSpace>
                  <ItemTwoColumns>
                    <ExtraUsersInput
                      value={selectedExtraUsers}
                      onChange={(e) => {
                        debounceExtraUsersChanged(e);
                      }}
                    />
                  </ItemTwoColumns>
                  <ItemTwoColumns>
                    <ItemColumnTitle>
                      <InputNumber
                        label="Final Price"
                        value={extraUsersFinalPrice ?? ''}
                        unit={getSymbolByCountryCode(countryCode)}
                        type={INPUT_NUMBER_TYPE.DECIMAL}
                        maxDecimals={12}
                        onChange={(e) => {
                          debounceExtraUsersFinalPriceChanged(e);
                        }}
                        disabled={disableExtraUsersCustomDiscountFields}
                      />
                      <InputNumber
                        label="Custom discount"
                        value={extraUsersDiscount ?? ''}
                        unit="%"
                        type={INPUT_NUMBER_TYPE.DECIMAL}
                        maxDecimals={12}
                        onChange={(e) => {
                          debounceExtraUsersCustomDiscountChanged(e);
                        }}
                        disabled={disableExtraUsersCustomDiscountFields}
                      />
                    </ItemColumnTitle>
                  </ItemTwoColumns>
                </TwoColumnsHalfSpace>
              )}
            </>
          )}
          {selectedWasiPlanId && (
            <ItemFullWidth>
              <InputSelect
                label="User to create account in Wasi"
                value={selectedUserForWasiAccount}
                choices={availableUsersForWasiAccount.map(({ email }) => ({
                  label: email,
                  value: email,
                }))}
                onSelect={onUserForWasiAccountSelected}
                disabled={availableUsersForWasiAccount.length === 0}
                required
                error={wasiAccountHasError}
                tip={
                  wasiAccountHasError
                    ? 'This user already has an active subscription in Wasi'
                    : ''
                }
              />
            </ItemFullWidth>
          )}
          {subscriptionRequestChangeSummary && selectedPlanId && (
            <>
              <ItemFullWidth>
                <Summary
                  subscriptionRenewalSummary={subscriptionRequestChangeSummary}
                  selectedBillingCycle={selectedBillingCycle}
                />
              </ItemFullWidth>
              {isUpsellNewCycleOrCurrentCycle &&
                // Eliminar esta condición de la FF UPSELL_WITH_CARD_4183 y dejar isUpsellNewCycleOrCurrentCycle && !isAutorenewalUpsellCurrentCycle
                (!UPSELL_WITH_CARD_4183 ||
                  !isAutorenewalUpsellCurrentCycle) && (
                  <ItemFullWidth>
                    <HelperText>
                      {selectedPublisherIsInGracePeriod && (
                        <>
                          Publisher subscription is in grace period, so a
                          request of this type can not be created
                        </>
                      )}
                      {!selectedPublisherIsInGracePeriod &&
                        (hasSetAFixedPriceOrCustomDiscount ? (
                          <>
                            As you have put a fixed price or a custom discount,
                            the amount to be paid will NOT be recalculated each
                            day.
                          </>
                        ) : (
                          <>
                            *Price will be subject to recalculation at the
                            moment the client pays for the upsell.
                          </>
                        ))}
                    </HelperText>
                  </ItemFullWidth>
                )}
            </>
          )}
        </>
      )}
    </>
  );
}

function changeSubscriptionRenewalBillingCycle({ value }) {
  dispatchMany([
    {
      id: SUBSCRIPTION_CHANGE_REQUEST_MODAL_BILLING_CYCLE_CHANGED,
      payload: { billingCycleInMonths: value },
    },
    {
      id: SUBSCRIPTION_CHANGE_REQUEST_MODAL_BILLING_DETAIL_REQUESTED,
    },
  ]);
}

function onPlanSelected({ value }) {
  dispatchMany([
    {
      id: SUBSCRIPTION_CHANGE_REQUEST_MODAL_PLAN_SELECTED,
      payload: {
        planId: value,
      },
    },
    {
      id: SUBSCRIPTION_CHANGE_REQUEST_MODAL_BILLING_DETAIL_REQUESTED,
    },
  ]);
}

function onMaxBoostablePropertiesSelected({ value }) {
  dispatchMany([
    {
      id: SUBSCRIPTION_CHANGE_REQUEST_MODAL_MAX_BOOSTABLE_PROPERTIES_SELECTED,
      payload: {
        maxBoostableProperties: value,
      },
    },
    {
      id: SUBSCRIPTION_CHANGE_REQUEST_MODAL_BILLING_DETAIL_REQUESTED,
    },
  ]);
}

function onBaseCustomDiscountChanged(e) {
  dispatch({
    id: SUBSCRIPTION_CHANGE_REQUEST_MODAL_BASE_CUSTOM_DISCOUNT_CHANGED,
    payload: {
      baseCustomDiscount: e.target.rawValue,
    },
  });
}

function onBaseFinalPriceChanged(e) {
  dispatchMany([
    {
      id: SUBSCRIPTION_CHANGE_REQUEST_MODAL_BASE_FINAL_PRICE_CHANGED,
      payload: {
        finalPrice: e.target.rawValue,
      },
    },
    {
      id: SUBSCRIPTION_CHANGE_REQUEST_MODAL_BILLING_DETAIL_REQUESTED,
    },
  ]);
}

function onSuperboostCustomDiscountChanged(e) {
  dispatch({
    id: SUBSCRIPTION_CHANGE_REQUEST_MODAL_SUPERBOOST_CUSTOM_DISCOUNT_CHANGED,
    payload: {
      superboostCustomDiscount: e.target.rawValue,
    },
  });
}

function onSuperboostFinalPriceChanged(e) {
  dispatchMany([
    {
      id: SUBSCRIPTION_CHANGE_REQUEST_MODAL_SUPERBOOST_FINAL_PRICE_CHANGED,
      payload: {
        finalPrice: e.target.rawValue,
      },
    },
    {
      id: SUBSCRIPTION_CHANGE_REQUEST_MODAL_BILLING_DETAIL_REQUESTED,
    },
  ]);
}

function onTypeOfClientSelected({ value }) {
  dispatchMany([
    {
      id: SUBSCRIPTION_CHANGE_REQUEST_MODAL_TYPE_OF_CLIENT_SELECTED,
      payload: {
        typeOfClient: value,
      },
    },
  ]);
}

function onWasiPlanSelected({ value }) {
  dispatchMany([
    {
      id: SUBSCRIPTION_CHANGE_REQUEST_MODAL_WASI_PLAN_SELECTED,
      payload: {
        wasiPlanId: value,
      },
    },
    SUBSCRIPTION_CHANGE_REQUEST_MODAL_BILLING_DETAIL_REQUESTED,
    SUBSCRIPTION_CHANGE_REQUEST_MODAL_PUBLISHER_USERS_REQUESTED,
  ]);
}

function onWasiPlanCustomDiscountChanged(e) {
  dispatchMany([
    {
      id: SUBSCRIPTION_CHANGE_REQUEST_MODAL_WASI_PLAN_CUSTOM_DISCOUNT_CHANGED,
      payload: {
        wasiPlanCustomDiscount: e.target.rawValue,
      },
    },
    {
      id: SUBSCRIPTION_CHANGE_REQUEST_MODAL_BILLING_DETAIL_REQUESTED,
    },
  ]);
}

function onWasiPlanFinalPriceChanged(e) {
  dispatchMany([
    {
      id: SUBSCRIPTION_CHANGE_REQUEST_MODAL_WASI_PLAN_FINAL_PRICE_CHANGED,
      payload: {
        finalPrice: e.target.rawValue,
      },
    },
    {
      id: SUBSCRIPTION_CHANGE_REQUEST_MODAL_BILLING_DETAIL_REQUESTED,
    },
  ]);
}

function onExtraUsersChange(e) {
  dispatchMany([
    {
      id: SUBSCRIPTION_CHANGE_REQUEST_MODAL_EXTRA_USERS_SELECTED,
      payload: {
        extraUsers: e.target.rawValue,
      },
    },
    {
      id: SUBSCRIPTION_CHANGE_REQUEST_MODAL_BILLING_DETAIL_REQUESTED,
    },
  ]);
}

function onExtraUsersCustomDiscountChanged(e) {
  dispatchMany([
    {
      id: SUBSCRIPTION_CHANGE_REQUEST_MODAL_EXTRA_USERS_CUSTOM_DISCOUNT_CHANGED,
      payload: {
        extraUsersCustomDiscount: e.target.rawValue,
      },
    },
    {
      id: SUBSCRIPTION_CHANGE_REQUEST_MODAL_BILLING_DETAIL_REQUESTED,
    },
  ]);
}

function onExtraUsersFinalPriceChanged(e) {
  dispatchMany([
    {
      id: SUBSCRIPTION_CHANGE_REQUEST_MODAL_EXTRA_USERS_FINAL_PRICE_CHANGED,
      payload: {
        finalPrice: e.target.rawValue,
      },
    },
    {
      id: SUBSCRIPTION_CHANGE_REQUEST_MODAL_BILLING_DETAIL_REQUESTED,
    },
  ]);
}

function onUserForWasiAccountSelected({ value }) {
  dispatch({
    id: SUBSCRIPTION_CHANGE_REQUEST_MODAL_WASI_USER_SELECTED,
    payload: { user: value },
  });
}

function NewSubscriptionDates({ summary }) {
  const intl = useIntl();
  const { startDate, nextBillingDate } = summary;
  return (
    <>
      {startDate && (
        <ItemTwoColumns>
          <InputText
            value={formatDate(intl, startDate) ?? ''}
            label="Upsell start date"
            readOnly
          />
        </ItemTwoColumns>
      )}
      {nextBillingDate && (
        <ItemTwoColumns>
          <InputText
            value={formatDate(intl, nextBillingDate) ?? ''}
            label="Upcoming subscription"
            readOnly
          />
        </ItemTwoColumns>
      )}
    </>
  );
}

function onMaxSuperboostableChange(e) {
  dispatchMany([
    {
      id: SUBSCRIPTION_CHANGE_REQUEST_MODAL_MAX_SUPERBOOSTABLE_PROPERTIES_SELECTED,
      payload: { maxSuperboostableProperties: Number(e.target.rawValue) },
    },
    {
      id: SUBSCRIPTION_CHANGE_REQUEST_MODAL_BILLING_DETAIL_REQUESTED,
    },
  ]);
}
