import { effects, registerEventHandler } from 'reffects';
import { state } from 'reffects-store';
import { http } from 'reffects-batteries';
import { environment } from '../../../../../coeffects/environment';
import { navigateTo, redirectTo } from '../../../../../effects/routing';
import { ACCEPTED_FILE_EXTENSIONS } from './constants';
import { doCreditCardPaymentIframeRoute } from '../../../../../utils/apiRouter';
import { translations } from '../../../../../coeffects/translations';
import {
  checkoutBillingInformationRoute,
  checkoutFailedRoute,
  checkoutProcessingRoute,
  checkoutSuccessRoute,
} from '../../../../../utils/proppitWebRouter';
import { analytics } from '../../../../../effects/analytics';
import {
  BANK_TRANSFER,
  CASH,
  CREDIT_CARD,
} from '../../../../../constants/paymentMethod';
import { addItem } from '../../../../../utils/formDataUtils';
import {
  CREDIT_CARD_DATA_LOAD_FAILED,
  CREDIT_CARD_DATA_LOADED,
} from '../../../../../events';

import { IS_LOGGED_IN } from '../../../../Authentication/constants';
import { REDIRECTED_TO_LOGIN } from '../../../../../App/events';

export const PAYMENT_METHOD_SELECTED = `PAYMENT_METHOD_SELECTED`;
export const FILE_UPLOADED = `FILE_UPLOADED`;
export const FILE_DELETED = `FILE_DELETED`;
export const TOGGLE_TERMS_AND_CONDITIONS_ACCEPTANCE = `TOGGLE_TERMS_AND_CONDITIONS_ACCEPTANCE`;
export const SUBMIT_PAYMENT_FORM = `SUBMIT_PAYMENT_FORM`;
export const SUBMIT_BANK_TRANSFER_FORM = `SUBMIT_BANK_TRANSFER_FORM`;
export const SUBMIT_BANK_TRANSFER_FORM_SUCCESS = `SUBMIT_BANK_TRANSFER_FORM_SUCCESS`;
export const SUBMIT_BANK_TRANSFER_FORM_FAILED = `SUBMIT_BANK_TRANSFER_FORM_FAILED`;
export const SUBMIT_CASH_FORM = `SUBMIT_CASH_FORM`;
export const SUBMIT_CASH_FORM_FAILED = `SUBMIT_CASH_FORM_FAILED`;
export const REQUEST_PAY_BY_LINK = `REQUEST_PAY_BY_LINK`;
export const REQUEST_PAY_BY_LINK_SUCCESS = `REQUEST_PAY_BY_LINK_SUCCESS`;
export const REQUEST_PAY_BY_LINK_FAILED = `REQUEST_PAY_BY_LINK_FAILED`;
export const BACK_TO_BILLING_INFO = `BACK_TO_BILLING_INFO`;
export const CHECKOUT_ERROR_PAGE = `CHECKOUT_ERROR_PAGE`;
export const PAYMENT_GATEWAY_FORM_OPERATION_SUCCEED = `PAYMENT_GATEWAY_FORM_OPERATION_SUCCEED`;
export const PAYMENT_GATEWAY_FORM_MOUNTED = `PAYMENT_GATEWAY_FORM_MOUNTED`;
export const ADVANCED_PAYMENT_METHODS_REQUESTED = `ADVANCED_PAYMENT_METHODS_REQUESTED`;
export const PAYMENT_AUTHORIZATION_CREATED = `PAYMENT_AUTHORIZATION_CREATED`;
export const PUBLISHER_SAVED_CREDIT_CARDS_REQUEST = `PUBLISHER_SAVED_CREDIT_CARDS_REQUEST`;
export const PAY_WITH_SAVED_CARD = `PAY_WITH_SAVED_CARD`;
export const PAY_WITH_SAVED_CARD_LOGIN_REQUIRED = `PAY_WITH_SAVED_CARD_LOGIN_REQUIRED`;
export const PAY_WITH_SAVED_CARD_PAYMENT_REQUEST = `PAY_WITH_SAVED_CARD_PAYMENT_REQUEST`;
export const PAY_WITH_SAVED_CARD_PAYMENT_SUCCESS = `PAY_WITH_SAVED_CARD_PAYMENT_SUCCESS`;
export const PAY_WITH_SAVED_CARD_PAYMENT_FAIL = `PAY_WITH_SAVED_CARD_PAYMENT_FAIL`;

const MAX_FILE_SIZE_IN_MEGABYTES = 10;

registerEventHandler(FILE_UPLOADED, (_, { file }) => {
  const filename = file.name.toLowerCase();
  if (ACCEPTED_FILE_EXTENSIONS.some((ext) => filename.endsWith(ext))) {
    const fileSizeInMegaBytes = file.size / 1024 / 1024;
    if (fileSizeInMegaBytes > MAX_FILE_SIZE_IN_MEGABYTES) {
      return state.set({ bankTransferReceiptFileSizeLimitExceededError: true });
    }
    return state.set({
      bankTransferReceipt: file,
      bankTransferReceiptFileSizeLimitExceededError: undefined,
    });
  }
  return state.set({
    bankTransferReceiptFileSizeLimitExceededError: undefined,
  });
});

registerEventHandler(FILE_DELETED, () =>
  state.set({ bankTransferReceipt: undefined })
);

registerEventHandler(
  TOGGLE_TERMS_AND_CONDITIONS_ACCEPTANCE,
  (_, termsAndConditionsAccepted) => state.set({ termsAndConditionsAccepted })
);

registerEventHandler(
  SUBMIT_PAYMENT_FORM,
  (_, { subscriptionChangeRequestId, selectedPaymentMethod }) =>
    effects.dispatch({
      id:
        selectedPaymentMethod === BANK_TRANSFER
          ? SUBMIT_BANK_TRANSFER_FORM
          : SUBMIT_CASH_FORM,
      payload: {
        subscriptionChangeRequestId,
        selectedPaymentMethod,
      },
    })
);

registerEventHandler(
  SUBMIT_BANK_TRANSFER_FORM,
  (
    { environment: { apiUrl }, state: { bankTransferReceipt } },
    { subscriptionChangeRequestId, selectedPaymentMethod }
  ) => ({
    ...state.merge({
      selectedPaymentMethod,
      form: { submitting: true },
    }),
    ...http.post({
      url: `${apiUrl}/subscription-change-requests/${subscriptionChangeRequestId}/bank-receipt`,
      body: addItem('bankReceipt', bankTransferReceipt),
      successEvent: {
        id: SUBMIT_BANK_TRANSFER_FORM_SUCCESS,
        payload: { subscriptionChangeRequestId, paymentMethod: BANK_TRANSFER },
      },
      errorEvent: {
        id: SUBMIT_BANK_TRANSFER_FORM_FAILED,
        payload: { subscriptionChangeRequestId, paymentMethod: BANK_TRANSFER },
      },
    }),
  }),
  [environment(), state.get({ bankTransferReceipt: 'bankTransferReceipt' })]
);

registerEventHandler(
  SUBMIT_CASH_FORM,
  (
    { environment: { apiUrl } },
    { subscriptionChangeRequestId, selectedPaymentMethod }
  ) => ({
    ...state.merge({
      selectedPaymentMethod,
      form: { submitting: true },
    }),
    ...http.post({
      url: `${apiUrl}/subscription-change-requests/${subscriptionChangeRequestId}/pay-by-link`,
      successEvent: {
        id: REQUEST_PAY_BY_LINK,
        payload: { subscriptionChangeRequestId },
      },
      errorEvent: {
        id: SUBMIT_CASH_FORM_FAILED,
        payload: { subscriptionChangeRequestId },
      },
    }),
  }),
  [environment()]
);

registerEventHandler(
  SUBMIT_CASH_FORM_FAILED,
  (_, { subscriptionChangeRequestId }) => ({
    ...state.merge({
      form: { submitting: false },
    }),
    ...analytics.trackClick(
      'submit-payment-error',
      'first-payment-checkout-payment-method',
      { paymentMethod: CASH, subscriptionChangeRequestId }
    ),
  })
);

registerEventHandler(
  PAYMENT_GATEWAY_FORM_OPERATION_SUCCEED,
  ({ state: { subRoute } }, { subscriptionChangeRequestId }) => ({
    ...navigateTo(
      checkoutProcessingRoute({ subscriptionChangeRequestId, subRoute }),
      true
    ),
    ...analytics.trackClick(
      'submit-payment',
      'first-payment-checkout-payment-method',
      { paymentMethod: CREDIT_CARD, subscriptionChangeRequestId }
    ),
  }),
  [state.get({ subRoute: 'checkoutConfig.subRoute' })]
);

registerEventHandler(
  SUBMIT_BANK_TRANSFER_FORM_SUCCESS,
  (
    { state: { subRoute } },
    [__, { subscriptionChangeRequestId, paymentMethod }]
  ) => ({
    ...navigateTo(
      checkoutSuccessRoute({ subscriptionChangeRequestId, subRoute })
    ),
    ...analytics.trackClick(
      'submit-payment',
      'first-payment-checkout-payment-method',
      { paymentMethod, subscriptionChangeRequestId }
    ),
  }),
  [state.get({ subRoute: 'checkoutConfig.subRoute' })]
);

registerEventHandler(
  SUBMIT_BANK_TRANSFER_FORM_FAILED,
  (
    { state: { subRoute } },
    [__, { subscriptionChangeRequestId, paymentMethod }]
  ) => ({
    ...state.merge({
      form: { submitting: false },
    }),
    ...navigateTo(
      checkoutFailedRoute({ subscriptionChangeRequestId, subRoute })
    ),
    ...analytics.trackClick(
      'submit-payment-error',
      'first-payment-checkout-payment-method',
      { paymentMethod, subscriptionChangeRequestId }
    ),
  }),
  [state.get({ subRoute: 'checkoutConfig.subRoute' })]
);

registerEventHandler(
  REQUEST_PAY_BY_LINK,
  ({ environment: { apiUrl } }, [_, { subscriptionChangeRequestId }]) => ({
    ...http.get({
      url: `${apiUrl}/subscription-change-requests/${subscriptionChangeRequestId}/pay-by-link`,
      successEvent: {
        id: REQUEST_PAY_BY_LINK_SUCCESS,
        payload: { subscriptionChangeRequestId },
      },
      errorEvent: {
        id: REQUEST_PAY_BY_LINK_FAILED,
        payload: { subscriptionChangeRequestId },
      },
    }),
  }),
  [environment()]
);

registerEventHandler(
  REQUEST_PAY_BY_LINK_SUCCESS,
  (
    _,
    [
      {
        data: { url },
      },
      { subscriptionChangeRequestId },
    ]
  ) => ({
    ...redirectTo(url),
    ...analytics.trackClick(
      'submit-payment',
      'first-payment-checkout-payment-method',
      { paymentMethod: CASH, subscriptionChangeRequestId }
    ),
  })
);

registerEventHandler(
  REQUEST_PAY_BY_LINK_FAILED,
  (_, { subscriptionChangeRequestId }) => ({
    ...state.merge({
      form: { submitting: false },
    }),
    ...analytics.trackClick(
      'submit-payment-error',
      'first-payment-checkout-payment-method',
      { paymentMethod: CASH, subscriptionChangeRequestId }
    ),
  })
);

registerEventHandler(
  BACK_TO_BILLING_INFO,
  ({ state: { subRoute } }, { subscriptionChangeRequestId }) => ({
    ...navigateTo(
      checkoutBillingInformationRoute({
        subRoute,
        subscriptionChangeRequestId,
      }),
      true
    ),
    ...analytics.trackClick(
      'back-to-billing-information',
      'first-payment-checkout-payment-method',
      { subscriptionChangeRequestId }
    ),
  }),
  [state.get({ subRoute: 'checkoutConfig.subRoute' })]
);

registerEventHandler(
  CHECKOUT_ERROR_PAGE,
  ({ state: { subRoute } }, { subscriptionChangeRequestId }) => ({
    ...navigateTo(
      checkoutFailedRoute({ subscriptionChangeRequestId, subRoute })
    ),
    ...analytics.trackClick(
      'submit-payment-error',
      'first-payment-checkout-payment-method',
      { paymentMethod: 'credit-card', subscriptionChangeRequestId }
    ),
  }),
  [state.get({ subRoute: 'checkoutConfig.subRoute' })]
);

registerEventHandler(
  PAYMENT_GATEWAY_FORM_MOUNTED,
  ({ environment: { apiUrl } }, { subscriptionChangeRequestId }) =>
    http.post({
      url: `${apiUrl}/subscription-change-requests/${subscriptionChangeRequestId}/card`,
      successEvent: {
        id: PAYMENT_AUTHORIZATION_CREATED,
        payload: { subscriptionChangeRequestId },
      },
    }),
  [environment()]
);

registerEventHandler(
  PAYMENT_AUTHORIZATION_CREATED,
  (
    { environment: { apiUrl }, state: { selectedPaymentMethod } },
    [_, { subscriptionChangeRequestId }]
  ) =>
    selectedPaymentMethod === CREDIT_CARD
      ? {
          ...http.get({
            url: `${apiUrl}/subscription-change-requests/${subscriptionChangeRequestId}/card`,
            successEvent: ADVANCED_PAYMENT_METHODS_REQUESTED,
          }),
          ...effects.dispatchLater({
            id: PAYMENT_AUTHORIZATION_CREATED,
            payload: [{}, { subscriptionChangeRequestId }],
            milliseconds: 30000,
          }),
        }
      : {},
  [environment(), state.get({ selectedPaymentMethod: 'selectedPaymentMethod' })]
);

registerEventHandler(
  ADVANCED_PAYMENT_METHODS_REQUESTED,
  (
    {
      environment: { paymentsApiUrl },
      state: { locale, showBackButton },
      // eslint-disable-next-line camelcase
      translations: { checkout_finishPayment },
    },
    [
      {
        data: { authorizationId, paymentRequestId },
      },
    ]
  ) =>
    state.set({
      'profile:paymentsUrl': doCreditCardPaymentIframeRoute(
        paymentsApiUrl,
        authorizationId,
        {
          lang: locale,
          hideTitle: true,
          showBackButton,
          ctaText: checkout_finishPayment,
        }
      ),
      'payment:paymentRequestId': paymentRequestId,
    }),
  [
    environment(),
    state.get({
      locale: 'locale',
      showBackButton: 'showBackButton',
    }),
    translations(['checkout_finishPayment']),
  ]
);

registerEventHandler(PAYMENT_METHOD_SELECTED, (_, { paymentMethod }) => ({
  ...state.set({
    selectedPaymentMethod: paymentMethod,
  }),
  ...effects.dispatch(PUBLISHER_SAVED_CREDIT_CARDS_REQUEST),
}));

registerEventHandler(
  PUBLISHER_SAVED_CREDIT_CARDS_REQUEST,
  ({ environment: { apiUrl }, state: { publisherId } }) => ({
    ...state.set({
      payment: { loading: true },
    }),
    ...http.get({
      url: `${apiUrl}/publishers/${publisherId}/saved-card`,
      successEvent: CREDIT_CARD_DATA_LOADED,
      errorEvent: CREDIT_CARD_DATA_LOAD_FAILED,
    }),
  }),
  [environment(), state.get({ publisherId: 'publisher.id' })]
);

registerEventHandler(
  PAY_WITH_SAVED_CARD,
  ({ state: { isLoggedIn } }) => {
    if (isLoggedIn) {
      return effects.dispatch(PAY_WITH_SAVED_CARD_PAYMENT_REQUEST);
    }

    return effects.dispatch(PAY_WITH_SAVED_CARD_LOGIN_REQUIRED);
  },
  [
    environment(),
    state.get({
      isLoggedIn: IS_LOGGED_IN,
    }),
  ]
);
registerEventHandler(
  PAY_WITH_SAVED_CARD,
  ({ environment: { apiUrl } }, { subscriptionChangeRequestId }) =>
    http.get({
      url: `${apiUrl}/subscription-change-requests/${subscriptionChangeRequestId}/saved-card`,
      successEvent: {
        id: PAYMENT_GATEWAY_FORM_OPERATION_SUCCEED,
        payload: { subscriptionChangeRequestId },
      },
      errorEvent: {
        id: PAY_WITH_SAVED_CARD_PAYMENT_FAIL,
        payload: { subscriptionChangeRequestId },
      },
    }),
  [environment()]
);

registerEventHandler(PAY_WITH_SAVED_CARD_LOGIN_REQUIRED, () =>
  effects.dispatch(REDIRECTED_TO_LOGIN)
);

registerEventHandler(
  PAY_WITH_SAVED_CARD_PAYMENT_SUCCESS,
  ({ state: { subRoute } }, { subscriptionChangeRequestId }) => ({
    ...navigateTo(
      checkoutProcessingRoute({ subscriptionChangeRequestId, subRoute }),
      true
    ),
    ...analytics.trackClick(
      'submit-payment',
      'checkout-payment-method-saved-card',
      { paymentMethod: CREDIT_CARD, subscriptionChangeRequestId }
    ),
  }),
  [state.get({ subRoute: 'checkoutConfig.subRoute' })]
);

registerEventHandler(
  PAY_WITH_SAVED_CARD_PAYMENT_FAIL,
  ({ state: { subRoute } }, [__, { subscriptionChangeRequestId }]) => ({
    ...state.merge({
      form: { submitting: false },
    }),
    ...navigateTo(
      checkoutFailedRoute({ subscriptionChangeRequestId, subRoute })
    ),
    ...analytics.trackClick(
      'submit-payment-error',
      'checkout-payment-method-saved-card',
      { paymentMethod: CREDIT_CARD, subscriptionChangeRequestId }
    ),
  }),
  [state.get({ subRoute: 'checkoutConfig.subRoute' })]
);
