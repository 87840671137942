import { Fragment } from 'react';
import styled from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import * as yup from 'yup';
import { useSelector } from 'reffects-store';
import { dispatch } from 'reffects';
import Dialog from 'design-system/components/Dialog/Dialog';
import {
  FlatButton,
  SecondaryButton,
} from 'design-system/components/Button/presets';
import ScrollableArea from 'design-system/components/ScrollableArea/ScrollableArea';
import { useDialog } from '../../../hooks/useDialog';
import { PUBLISHER_PROFILE_EDIT_DIALOG } from '../constants';
import useInternationalization from '../../../hooks/useInternationalization';
import { Form } from '../Form';
import CompanyLogoField from './CompanyLogoField';
import CompanyBackgroundField from './CompanyBackgroundField';
import TextField from '../../../partials/fields/TextField';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import MultiLangAddressField from './MultiLangAddressField';
import { DESCRIPTION_MAX_LENGTH } from './constants';
import MultiLangDescriptionField from './MultiLangDescriptionField';
import {
  isMultilangCountrySelector,
  propertySupportedLanguagesSelector,
} from '../../../selectors/config';
import MultiLangNameField from './MultiLangNameField';
import { PUBLISHER_SAVE_DETAILS } from './events';
import { publisherProfileCurrentLocaleSelector } from '../selectors';

const Fields = styled.div`
  > * {
    ${spacing.stack(SPACING_SIZE.M)}
  }

  ${spacing.inline(SPACING_SIZE.S, false)}
`;

export default function EditPublisherForm() {
  const {
    closeDialog,
    parameters: { autoFocusDescriptionByLocale = false, ...parameters },
  } = useDialog(PUBLISHER_PROFILE_EDIT_DIALOG);
  const currentSelectedLocale = useSelector(
    publisherProfileCurrentLocaleSelector
  );
  const locales = useSelector(propertySupportedLanguagesSelector);
  const isPublisherMultilang = useSelector(isMultilangCountrySelector);
  const validationSchema = useValidationSchema(locales);
  const defaultValues = useDefaultValues(parameters);
  const handleFormSubmit = ({ companyLogo, companyBackground, ...data }) => {
    dispatch({
      id: PUBLISHER_SAVE_DETAILS,
      payload: {
        ...data,
        companyLogo: typeof companyLogo === 'string' ? undefined : companyLogo,
        companyBackground:
          typeof companyBackground === 'string' ? undefined : companyBackground,
      },
    });

    closeDialog();
  };

  return (
    <Form
      defaultValues={defaultValues}
      validationSchema={validationSchema}
      onSubmit={handleFormSubmit}
    >
      <Dialog.Content>
        <ScrollableArea maxHeight={500}>
          <Fields>
            <CompanyLogoField name="companyLogo" />
            <CompanyBackgroundField name="companyBackground" />
            {locales.map((locale, index) => (
              <Fragment key={locale}>
                <MultiLangNameField
                  label={
                    <FormattedMessageWithValidation id="company_profile_name_title" />
                  }
                  locale={locale}
                  showFlagIcon={isPublisherMultilang}
                  required={index === 0}
                />
                <MultiLangAddressField
                  label={
                    <FormattedMessageWithValidation id="company_profile_address_field_title" />
                  }
                  locale={locale}
                  showFlagIcon={isPublisherMultilang}
                />
                <MultiLangDescriptionField
                  label={
                    <FormattedMessageWithValidation id="company_profile_edit_company_description_section_title" />
                  }
                  locale={locale}
                  showFlagIcon={isPublisherMultilang}
                  minRows={6}
                  maxRows={6}
                  autoResize
                  autoFocus={
                    autoFocusDescriptionByLocale &&
                    currentSelectedLocale === locale
                  }
                />
              </Fragment>
            ))}
            <TextField
              label={
                <FormattedMessageWithValidation id="company_profile_url_field_title" />
              }
              name="url"
              maxlength="255"
            />
          </Fields>
        </ScrollableArea>
      </Dialog.Content>
      <Dialog.Footer>
        <FlatButton onClick={closeDialog}>
          <FormattedMessageWithValidation id="notification_visibility_disable_cancel" />
        </FlatButton>
        <SecondaryButton type="submit">
          <FormattedMessageWithValidation id="confirm_dialog_update_cta" />
        </SecondaryButton>
      </Dialog.Footer>
    </Form>
  );
}

function useDefaultValues(parameters) {
  const {
    names,
    addresses,
    descriptions,
    url,
    companyLogo,
    companyBackground,
  } = parameters;

  return {
    url,
    companyLogo,
    companyBackground,
    names: composeMultiLangValue(names),
    addresses: composeMultiLangValue(addresses),
    descriptions: composeMultiLangValue(descriptions),
  };
}

function composeMultiLangValue(multiLangValue) {
  return multiLangValue.reduce(
    (acc, value) => ({
      ...acc,
      [value.locale]: value.text,
    }),
    {}
  );
}

function useValidationSchema(locales) {
  const intl = useInternationalization();

  const descriptionsSchema = locales.reduce(
    (acc, locale) => ({
      ...acc,
      [locale]: yup.string().max(DESCRIPTION_MAX_LENGTH, ({ max }) =>
        intl.formatMessageWithValidation({
          id: 'form_validations_max_length_reached',
          values: {
            max,
          },
        })
      ),
    }),
    {}
  );

  return {
    names: yup.object({
      [locales[0]]: yup.string().required(
        intl.formatMessageWithValidation({
          id: 'form_validations_required_field',
        })
      ),
    }),
    descriptions: yup.object(descriptionsSchema),
    url: yup.string().url().nullable(),
  };
}
