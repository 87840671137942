import styled from 'styled-components';
import { useSelector } from 'reffects-store';
import { useCallback, useState } from 'react';
import Loader from 'design-system/components/Loader';
import Anchor from 'design-system/components/Anchor';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Body2 } from 'design-system/components/Typography/presets/Body2';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import PaymentSummary from '../../partials/Summary/Summary';
import { proformaLinkSelector, showProformaLinkSelector } from './selectors';
import useTracking from '../../../../hooks/useTracking';
import InformationBlock from '../../partials/InformationBlock';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  > * {
    ${spacing.stack(SPACING_SIZE.M)}
  }
`;

const DownloadInvoice = styled(InformationBlock)`
  ${Loader} {
    width: 16px;
    height: 16px;
    border-width: 2px;
    ${spacing.inline(SPACING_SIZE.S, false)}
  }
`;

function Summary({ subscriptionChangeRequestId, ...props }) {
  const showProformaLink = useSelector(showProformaLinkSelector);
  const downloadLink = useSelector(proformaLinkSelector);
  const [linkElement, setLinkElement] = useState(null);
  const ref = useCallback((node) => {
    if (node != null && linkElement !== node) setLinkElement(node);
  }, []);
  useTracking({
    ref: linkElement,
    trackClick: {
      eventName: 'download-proforma-invoice',
      payload: { subscriptionChangeRequestId },
    },
    trackImpression: {
      elementName: 'proforma-invoice-link',
      payload: { subscriptionChangeRequestId },
    },
    sectionName: 'first-payment-checkout-summary',
  });
  return (
    <Wrapper {...props}>
      <PaymentSummary />
      {showProformaLink && (
        <DownloadInvoice>
          {downloadLink ? (
            <Anchor href={downloadLink} target="_blank" ref={ref}>
              <FormattedMessageWithValidation id="checkout_downloadProforma" />
            </Anchor>
          ) : (
            <>
              <Loader />
              <Body2>
                <FormattedMessageWithValidation id="checkout_downloadProforma_waiting" />
              </Body2>
            </>
          )}
        </DownloadInvoice>
      )}
    </Wrapper>
  );
}

export default Summary;
