export const PROPERTY_TYPE_APARTMENT = 'apartment';
export const PROPERTY_TYPE_STUDIO_APARTMENT = 'studio apartment';
export const PROPERTY_TYPE_HOUSE = 'house';
export const PROPERTY_TYPE_CONDO = 'condo';
export const PROPERTY_TYPE_VILLA = 'villa';
export const PROPERTY_TYPE_COMMERCIAL = 'commercial';
export const PROPERTY_TYPE_LAND = 'land';
export const PROPERTY_TYPE_TOWNHOUSE = 'townhouse';
export const PROPERTY_TYPE_SERVICED_APARTMENT = 'serviced-apartment';
export const PROPERTY_TYPE_OFFICE = 'office';
export const PROPERTY_TYPE_INDUSTRIAL_UNIT = 'industrial unit';
export const PROPERTY_TYPE_CAR_PARK = 'car park';
export const PROPERTY_TYPE_STUDIO = 'studio';
export const PROPERTY_TYPE_ROOM = 'room';
export const PROPERTY_TYPE_BEACH_HOUSE = 'beach house';
export const PROPERTY_TYPE_HOTEL = 'hotel';
export const PROPERTY_TYPE_SUITE = 'suite';
export const PROPERTY_TYPE_FLAT = 'flat';
export const PROPERTY_TYPE_PENTHOUSE = 'penthouse';
export const PROPERTY_TYPE_BUNGALOW = 'bungalow';
export const PROPERTY_TYPE_DUPLEX = 'duplex';
export const PROPERTY_TYPE_RUSTIC_HOUSE = 'rustic house';
export const PROPERTY_TYPE_FIRST_FLOOR = 'first floor';
export const PROPERTY_TYPE_BUILDING = 'building';
export const PROPERTY_TYPE_STORAGE_ROOM = 'storage room';
export const PROPERTY_TYPE_RANCH = 'ranch';
export const PROPERTY_TYPE_QUINTA = 'quinta';
export const PROPERTY_TYPE_GATED_COMMUNITY_HOUSE = 'house in gated community';
export const PROPERTY_TYPE_HORIZONTAL_CONDO = 'horizontal condo';
export const PROPERTY_TYPE_LOFT = 'loft';
export const PROPERTY_TYPE_COMMERCIAL_IN_MALL = 'commercial in mall';
export const PROPERTY_TYPE_COMMERCIAL_LAND = 'commercial land';
export const PROPERTY_TYPE_DOCTOR_OFFICE = 'doctor office';
export const PROPERTY_TYPE_AGRICULTURAL_LAND = 'agricultural land';

export const AMENITY_ITEM_AIR_CONDITIONING = 'air conditioning';
export const AMENITY_ITEM_ALARM = 'alarm';
export const AMENITY_ITEM_BALCONY = 'balcony';
export const AMENITY_ITEM_BUILTIN_WARDROBE = 'built-in wardrobe';
export const AMENITY_ITEM_CAR_PARK = 'car park';
export const AMENITY_ITEM_CELLAR = 'cellar';
export const AMENITY_ITEM_CHILDRENS_AREA = "children's area";
export const AMENITY_ITEM_CONCIERGE = 'concierge';
export const AMENITY_ITEM_DISABLED_ACCESS = 'disabled access';
export const AMENITY_ITEM_ELECTRICITY = 'electricity';
export const AMENITY_ITEM_EQUIPPED_KITCHEN = 'equipped kitchen';
export const AMENITY_ITEM_FIREPLACE = 'fireplace';
export const AMENITY_ITEM_GARDEN = 'garden';
export const AMENITY_ITEM_GRILL = 'grill';
export const AMENITY_ITEM_GUARDHOUSE = 'guardhouse';
export const AMENITY_ITEM_GYM = 'gym';
export const AMENITY_ITEM_INTERNET = 'internet';
export const AMENITY_ITEM_HEATING = 'heating';
export const AMENITY_ITEM_INTEGRAL_KITCHEN = 'integral kitchen';
export const AMENITY_ITEM_JACUZZI = 'jacuzzi';
export const AMENITY_ITEM_LIFT = 'lift';
export const AMENITY_ITEM_LIBRARY = 'library';
export const AMENITY_ITEM_NATURAL_GAS = 'natural gas';
export const AMENITY_ITEM_OFFICE = 'office';
export const AMENITY_ITEM_PANORAMIC_VIEW = 'panoramic view';
export const AMENITY_ITEM_ROOF_GARDEN = 'roof garden';
export const AMENITY_ITEM_TENNIS_COURT = 'tennis court';
export const AMENITY_ITEM_TERRACE = 'terrace';
export const AMENITY_ITEM_SAUNA = 'sauna';
export const AMENITY_ITEM_SECURITY = 'security';
export const AMENITY_ITEM_SERVICE_ROOM = 'service room';
export const AMENITY_ITEM_SWIMMING_POOL = 'swimming pool';
export const AMENITY_ITEM_VIDEO_CABLE = 'video cable';
export const AMENITY_ITEM_WATER = 'water';
export const AMENITY_ITEM_WATER_TANK = 'water tank';
export const AMENITY_ITEM_YARD = 'yard';
export const AMENITY_ITEM_STORAGE_ROOM = 'storage room';
export const AMENITY_ITEM_SECURITY_DOOR = 'security door';
export const AMENITY_ITEM_GREEN_AREA = 'green area';
export const AMENITY_ITEM_SEMI_DETACHED = 'semi-detached';
export const AMENITY_ITEM_INTERCOM = 'intercom';
export const AMENITY_ITEM_EXTERIOR = 'exterior';
export const AMENITY_ITEM_CCTV = 'cctv';
export const AMENITY_ITEM_CLEANING_ROOM = 'cleaning room';
export const AMENITY_ITEM_MULTIUSE_ROOM = 'multiuse room';

export const propertyTypesTranslationTags = new Map([
  [PROPERTY_TYPE_APARTMENT, 'newprop_proptype1'],
  [PROPERTY_TYPE_HOUSE, 'newprop_proptype2'],
  [PROPERTY_TYPE_CONDO, 'newprop_proptype'],
  [PROPERTY_TYPE_VILLA, 'newprop_proptype4'],
  [PROPERTY_TYPE_COMMERCIAL, 'newprop_proptype7'],
  [PROPERTY_TYPE_LAND, 'newprop_proptype11'],
  [PROPERTY_TYPE_TOWNHOUSE, 'newprop_proptype5'],
  [PROPERTY_TYPE_SERVICED_APARTMENT, 'newprop_proptype6'],
  [PROPERTY_TYPE_OFFICE, 'newprop_proptype8'],
  [PROPERTY_TYPE_INDUSTRIAL_UNIT, 'newprop_proptype9'],
  [PROPERTY_TYPE_CAR_PARK, 'newprop_proptype10'],
  [PROPERTY_TYPE_STUDIO, 'newprop_proptype18'],
  [PROPERTY_TYPE_ROOM, 'newprop_proptype14'],
  [PROPERTY_TYPE_BEACH_HOUSE, 'newprop_proptype13'],
  [PROPERTY_TYPE_HOTEL, 'ad_form_property_type_hotel'],
  [PROPERTY_TYPE_SUITE, 'ad_form_property_type_suite'],
  [PROPERTY_TYPE_FLAT, 'ad_form_property_type_flat'],
  [PROPERTY_TYPE_PENTHOUSE, 'ad_form_property_type_penthouse'],
  [PROPERTY_TYPE_BUNGALOW, 'ad_form_property_type_bungalow'],
  [PROPERTY_TYPE_DUPLEX, 'ad_form_property_type_duplex'],
  [PROPERTY_TYPE_RUSTIC_HOUSE, 'ad_form_property_type_rustic_house'],
  [PROPERTY_TYPE_FIRST_FLOOR, 'ad_form_property_type_first_floor'],
  [PROPERTY_TYPE_BUILDING, 'ad_form_property_type_building'],
  [PROPERTY_TYPE_STORAGE_ROOM, 'ad_form_property_type_storage_room'],
  [PROPERTY_TYPE_RANCH, 'ad_form_property_type_ranch'],
  [PROPERTY_TYPE_QUINTA, 'ad_form_property_type_quinta'],
  [PROPERTY_TYPE_GATED_COMMUNITY_HOUSE, 'newprop_proptype15'],
  [PROPERTY_TYPE_HORIZONTAL_CONDO, 'newprop_proptype16'],
  [PROPERTY_TYPE_LOFT, 'newprop_proptype17'],
  [PROPERTY_TYPE_COMMERCIAL_IN_MALL, 'newprop_proptype19'],
  [PROPERTY_TYPE_COMMERCIAL_LAND, 'newprop_proptype20'],
  [PROPERTY_TYPE_DOCTOR_OFFICE, 'newprop_proptype21'],
  [PROPERTY_TYPE_AGRICULTURAL_LAND, 'newprop_proptype11'],
  [PROPERTY_TYPE_STUDIO_APARTMENT, 'newprop_proptype22'],
]);

export const furnishedTranslationTags = new Map([
  ['fully', 'newprop_furnished_fully'],
  ['partly', 'newprop_furnished_partly'],
  ['unfurnished', 'newprop_furnished_unfurnished'],
]);

export const ownershipTranslationTags = new Map([
  ['freehold', 'newprop_ownership_free'],
  ['leasehold', 'newprop_ownership_lease'],
]);

export const tenureInYearsTranslationTags = new Map([
  [1, 'newprop_duration_1'],
  [3, 'newprop_duration_3'],
  [6, 'newprop_duration_6'],
  [9, 'newprop_duration_9'],
  [12, 'newprop_duration_12'],
]);

export const areaUnitsLabels = new Map([
  ['sqm', 'm²'],
  ['sf', 'ft²'],
  ['sqwa', 'ad_form_usable_area_unit_sqwa'],
  ['rai/ngan/sqw', 'ad_form_land_area_unit_th'],
]);

export const OPERATION_TYPE_RENT = 'rent';
export const OPERATION_TYPE_SELL = 'sell';
export const OPERATION_TYPE_SELL_AND_RENT = 'sell-and-rent';
export const operationTypeCaptions = new Map([
  [OPERATION_TYPE_SELL, 'newprop_operation_sell'],
  [OPERATION_TYPE_RENT, 'newprop_operation_rent'],
  [OPERATION_TYPE_SELL_AND_RENT, 'operation_type_sell_and_rent'],
]);

export const amenitiesTranslationTags = new Map([
  [AMENITY_ITEM_AIR_CONDITIONING, 'newprop_amenities7'],
  [AMENITY_ITEM_ALARM, 'newprop_amenities_alarm'],
  [AMENITY_ITEM_BALCONY, 'newprop_amenities_balcony'],
  [AMENITY_ITEM_BUILTIN_WARDROBE, 'newprop_amenities_wardroble'],
  [AMENITY_ITEM_CAR_PARK, 'newprop_amenities2'],
  [AMENITY_ITEM_CELLAR, 'newprop_amenities_wine_cellar'],
  [AMENITY_ITEM_CHILDRENS_AREA, 'newprop_amenities9'],
  [AMENITY_ITEM_CONCIERGE, 'newprop_amenities6'],
  [AMENITY_ITEM_DISABLED_ACCESS, 'newprop_amenities_disabled_access'],
  [AMENITY_ITEM_ELECTRICITY, 'newprop_amenities_electricity'],
  [AMENITY_ITEM_EQUIPPED_KITCHEN, 'newprop_amenities_kitchen_equipped'],
  [AMENITY_ITEM_FIREPLACE, 'newprop_amenities_fireplace'],
  [AMENITY_ITEM_GARDEN, 'newprop_amenities11'],
  [AMENITY_ITEM_GRILL, 'newprop_amenities5'],
  [AMENITY_ITEM_GUARDHOUSE, 'newprop_amenities_surveillance_booth'],
  [AMENITY_ITEM_GYM, 'newprop_amenities8'],
  [AMENITY_ITEM_INTERNET, 'newprop_amenities10'],
  [AMENITY_ITEM_HEATING, 'newprop_amenities_heating'],
  [AMENITY_ITEM_INTEGRAL_KITCHEN, 'newprop_amenities_kitchen_builtin'],
  [AMENITY_ITEM_JACUZZI, 'newprop_amenities_jacuzzi'],
  [AMENITY_ITEM_LIFT, 'newprop_amenities_elevator'],
  [AMENITY_ITEM_LIBRARY, 'newprop_amenities_library'],
  [AMENITY_ITEM_NATURAL_GAS, 'newprop_amenities_natural_gas'],
  [AMENITY_ITEM_OFFICE, 'newprop_amenities_office'],
  [AMENITY_ITEM_PANORAMIC_VIEW, 'newprop_amenities_views_panoramic'],
  [AMENITY_ITEM_ROOF_GARDEN, 'newprop_amenities_roof_garden'],
  [AMENITY_ITEM_TENNIS_COURT, 'newprop_amenities_tennis_court'],
  [AMENITY_ITEM_TERRACE, 'newprop_amenities_terrace'],
  [AMENITY_ITEM_SAUNA, 'newprop_amenities_sauna'],
  [AMENITY_ITEM_SECURITY, 'newprop_amenities12'],
  [AMENITY_ITEM_SERVICE_ROOM, 'newprop_amenities_utility_room'],
  [AMENITY_ITEM_SWIMMING_POOL, 'newprop_amenities3'],
  [AMENITY_ITEM_VIDEO_CABLE, 'newprop_amenities4'],
  [AMENITY_ITEM_WATER, 'newprop_amenities_water'],
  [AMENITY_ITEM_WATER_TANK, 'newprop_amenities_cistern'],
  [AMENITY_ITEM_YARD, 'newprop_amenities_patio'],
  [AMENITY_ITEM_STORAGE_ROOM, 'newprop_amenities_storage_room'],
  [AMENITY_ITEM_SECURITY_DOOR, 'newprop_amenities_security_door'],
  [AMENITY_ITEM_GREEN_AREA, 'newprop_amenities_green_area'],
  [AMENITY_ITEM_SEMI_DETACHED, 'newprop_amenities_semi_detached'],
  [AMENITY_ITEM_INTERCOM, 'newprop_amenities_intercom'],
  [AMENITY_ITEM_EXTERIOR, 'newprop_amenities_views_exterior'],
  [AMENITY_ITEM_CCTV, 'newprop_amenities_cctv'],
  [AMENITY_ITEM_CLEANING_ROOM, 'newprop_amenities_cleaning_room'],
  [AMENITY_ITEM_MULTIUSE_ROOM, 'newprop_amenities_multiuse_room'],
]);

export const nearbyLocationsTranslationTags = new Map([
  ['main street', 'propspage_table_nearby_avenues'],
  ['park', 'propspage_table_nearby_parks'],
  ['school', 'propspage_table_nearby_schools'],
  ['sea', 'newprop_surroundings_seaside'],
  ['shopping mall', 'propspage_table_nearby_malls'],
  ['train station', 'newprop_surroundings_train'],
  ['main street', 'newprop_table_nearby_avenues'],
  ['supermarket', 'newprop_table_nearby_supermarkets'],
  ['medical center', 'newprop_table_nearby_medical_centers'],
]);

export const rulesTranslationTags = new Map([
  ['pets allowed', 'propspage_table_pets_allowed'],
  ['children allowed', 'propspage_table_children_allowed'],
  ['only families', 'propspage_table_families'],
]);

export const operationTypeTranslationsTags = new Map([
  ['sell', 'properties_list_operation_type_sale'],
  ['rent', 'properties_list_operation_type_rent'],
]);

export const PROPERTY_LOCATION_VISIBILITY = {
  APPROXIMATE: 'approximate',
  ACCURATE: 'accurate',
};
