export function creditCardSelector({ payment }) {
  return payment?.creditCard;
}

export function hasCreditCardSavedSelector(state) {
  const hasCreditCardSaved = creditCardSelector(state)?.creditCardType;
  return !!hasCreditCardSaved;
}

export function isLoadingSavedCardSelector(state) {
  return !state.payment || (state.payment.loading ?? false);
}
