import { effects, registerEventHandler } from 'reffects';
import { state } from 'reffects-store';
import { globals, http } from 'reffects-batteries';
import { toast } from '../effects/toast';
import { authTokenRemove } from '../effects/authToken';
import { navigateTo, openNewTab } from '../effects/routing';
import { environment } from '../coeffects/environment';
import { location } from '../coeffects/location';
import { CASH } from '../constants/paymentMethod';
import { queryParamsGetAll } from '../coeffects/queryParamsAll';
import { loadTranslations } from '../effects/loadTranslations';
import { localeFromNavigatorLanguage } from '../utils/locale';
import { renderApp } from '../effects/renderApp';
import { localStorage } from '../coeffects/localStorage';
import { pageReload } from '../effects/pageReload';
import { IS_LOGGED_IN } from '../pages/Authentication/constants';
import { LOGIN } from '../constants/routes';
import { COUNTRY_CONFIG_STATE_REGION } from '../config';
import { device } from '../coeffects/device';

export const REDIRECTED_TO_LOGIN = 'REDIRECTED_TO_LOGIN';
export const REDIRECTED_TO_MAINTENANCE = 'REDIRECTED_TO_MAINTENANCE';
export const LOGOUT_USER_REQUESTED = 'LOGOUT_USER_REQUESTED';
export const CHANGE_PASSWORD_PAGE_REQUESTED = 'CHANGE_PASSWORD_PAGE_REQUESTED';
export const COUNTRY_CONFIG_LOADED = 'COUNTRY_CONFIG_LOADED';
export const OPEN_LINK_IN_A_NEW_TAB = 'OPEN_LINK_IN_A_NEW_TAB';
export const REFERRER_URL_SAVED = 'REFERRER_URL_SAVED';
export const CHECKOUT_PAGE_INITIALIZED = 'CHECKOUT_PAGE_INITIALIZED';
export const GLOBAL_VALUES_LOADED = 'GLOBAL_VALUES_LOADED';
export const TRANSLATIONS_LOADED_WITH_RENDER =
  'TRANSLATIONS_LOADED_WITH_RENDER';
export const UPDATE_USER_LOCALE = 'UPDATE_USER_LOCALE';
export const UPDATE_USER_LOCALE_SUCCESS = 'UPDATE_USER_LOCALE_SUCCESS';
export const FORCE_LOCALE = 'FORCE_LOCALE';

export default function registerAppEvents() {
  registerEventHandler(
    REDIRECTED_TO_LOGIN,
    ({
      globals: {
        navigator: { language },
      },
    }) => {
      const locale = localeFromNavigatorLanguage(language);

      return {
        ...navigateTo(LOGIN),
        ...state.set({
          [[IS_LOGGED_IN]]: false,
        }),
        ...loadTranslations({
          locale,
          onFinishEventId: TRANSLATIONS_LOADED_WITH_RENDER,
        }),
      };
    },
    [globals.get('navigator')]
  );

  registerEventHandler(
    REDIRECTED_TO_MAINTENANCE,
    ({
      globals: {
        navigator: { language },
      },
    }) => {
      const locale = localeFromNavigatorLanguage(language);

      return {
        ...state.set({
          maintenance: true,
        }),
        ...loadTranslations({
          locale,
          onFinishEventId: TRANSLATIONS_LOADED_WITH_RENDER,
        }),
      };
    },
    [globals.get('navigator')]
  );

  registerEventHandler(
    LOGOUT_USER_REQUESTED,
    (_, toastTag = 'notification_logout_success') => ({
      ...effects.dispatch(REDIRECTED_TO_LOGIN),
      ...authTokenRemove(),
      ...toast.show({
        text: toastTag,
      }),
      ...state.set({
        'layout:isHeaderMenuOpen': false,
      }),
    })
  );

  registerEventHandler(CHANGE_PASSWORD_PAGE_REQUESTED, () => ({
    ...navigateTo('/change-password'),
    ...state.set({
      'layout:isHeaderMenuOpen': false,
    }),
  }));

  registerEventHandler(
    UPDATE_USER_LOCALE,
    (
      {
        environment: { apiUrl },
        state: { userId, userEmail, userPublisherId, userRole },
      },
      newLocale
    ) => {
      const userToUpdate = {
        id: userId,
        publisherId: userPublisherId,
        locale: newLocale,
        email: userEmail,
        role: userRole,
      };
      return {
        ...http.put({
          url: `${apiUrl}/backoffice/users/${userToUpdate.id}`,
          body: userToUpdate,
          successEvent: UPDATE_USER_LOCALE_SUCCESS,
        }),
      };
    },
    [
      environment(),
      state.get({
        userId: 'user.id',
        userEmail: 'user.email',
        userRole: 'user.role',
        userPublisherId: 'publisher:id',
      }),
    ]
  );

  registerEventHandler(UPDATE_USER_LOCALE_SUCCESS, () => pageReload());

  registerEventHandler(COUNTRY_CONFIG_LOADED, (_, [payload]) =>
    state.set({
      [COUNTRY_CONFIG_STATE_REGION]: payload,
    })
  );

  registerEventHandler(OPEN_LINK_IN_A_NEW_TAB, (_, { url, target }) =>
    openNewTab(url, target)
  );

  registerEventHandler(
    REFERRER_URL_SAVED,
    ({ state: { userNavigation = {} }, location: { href } }) =>
      state.set({
        userNavigation: {
          referrerUrl: userNavigation.currentUrl ?? null,
          currentUrl: href,
        },
      }),

    [state.get({ userNavigation: 'userNavigation' }), location()]
  );

  registerEventHandler(
    CHECKOUT_PAGE_INITIALIZED,
    ({ queryParamsAll: { method } }) =>
      state.set({
        'publisher:cashCheckoutRequested': method === CASH,
      }),
    [queryParamsGetAll()]
  );

  registerEventHandler(
    GLOBAL_VALUES_LOADED,
    ({
      localStorage: {
        interstitialClosed,
        notUsingWholePlanBannerClosedDate,
        fakeDoorModalDisabledDate,
      },
      device: deviceInfo,
    }) =>
      state.set({
        interstitialClosed,
        notUsingWholePlanBannerClosedDate: notUsingWholePlanBannerClosedDate
          ? Date.parse(notUsingWholePlanBannerClosedDate)
          : null,
        fakeDoorModalDisabledDate: fakeDoorModalDisabledDate
          ? Date.parse(fakeDoorModalDisabledDate)
          : null,
        device: deviceInfo,
      }),
    [
      localStorage.getItems([
        'interstitialClosed',
        'notUsingWholePlanBannerClosedDate',
        'fakeDoorModalDisabledDate',
      ]),
      device(),
    ]
  );

  registerEventHandler(
    TRANSLATIONS_LOADED_WITH_RENDER,
    (_, { translations, locale }) => ({
      ...state.set({ translations, locale }),
      ...renderApp(),
    })
  );

  registerEventHandler(FORCE_LOCALE, (_, { locale }) => ({
    ...state.set({ locale }),
    ...loadTranslations({
      locale,
      onFinishEventId: TRANSLATIONS_LOADED_WITH_RENDER,
    }),
  }));
}
