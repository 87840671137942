import styled, { css } from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { useEffect, useRef, useState } from 'react';
import Popover from 'design-system/components/Popover';
import Menu from 'design-system/components/Menu/Menu';
import { Body1 } from 'design-system/components/Typography/presets/Body1';

const MenuItem = styled(Menu.Item)`
  ${spacing.inset(SPACING_SIZE.M)}
  width: 100%;

  svg {
    ${spacing.inline(SPACING_SIZE.S)}
  }

  ${(props) =>
    props.theme?.isMobile &&
    css`
      ${Body1} {
        text-wrap: wrap;
      }
    `}
`;

const modMenuPositionTop = () => css`
  bottom: calc(100% + ${spacing.value(SPACING_SIZE.S)});
`;

const Wrapper = styled(Popover)`
  position: absolute;
  left: 0;

  ${({ menuOnTop }) => menuOnTop && modMenuPositionTop()}
  ${(props) => props.theme?.isMobile && 'width: 85vw;'}
`;

export default function PopoverMenu({
  handleSelection,
  handleCancellation,
  choices,
}) {
  const { ref, isFullyVisible } = useIsFullyVisible();

  return (
    <Wrapper ref={ref} menuOnTop={!isFullyVisible}>
      <Menu
        onConfirm={handleSelection}
        onCancel={handleCancellation}
        scrollable
      >
        {choices.map((choiceProps) => (
          <MenuItem key={choiceProps.id} {...choiceProps} />
        ))}
      </Menu>
    </Wrapper>
  );
}

function useIsFullyVisible() {
  const ref = useRef(null);
  const [isFullyVisible, setIsInViewport] = useState(true);

  useEffect(() => {
    const { bottom } = ref.current?.getBoundingClientRect();
    setIsInViewport(bottom < window.innerHeight);
  }, []);

  return { ref, isFullyVisible };
}
