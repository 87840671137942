import styled from 'styled-components';
import { useMount } from 'react-use';
import { dispatch } from 'reffects';
import { useCallback } from 'react';
import { useSelector } from 'reffects-store';
import { ROWS_PER_PAGE } from 'design-system/components/Table';
import Tag, { TAG_VARIANT } from 'design-system/components/Tag';
import Button from 'design-system/components/Button';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import Layout from '../partials/Layout/Layout';
import { COLLECTION_NAME } from './constants';
import { CREATE_NEW_CAMPAIGN, DEVELOPERS_REQUESTED } from './events';
import CustomFormattedDate from '../../../partials/FormattedDate/CustomFormattedDate';
import { RenewCampaignModal } from './RenewCampaignModal';
import { CreateCampaignModal } from './CreateCampaignModal';
import { EditCampaignModal } from './EditCampaignModal';
import { createCampaignsTableAdapter } from './campaignsTableAdapter';
import {
  DataTable,
  DataTableProvider,
  PriceColumn,
  TextColumn,
} from '../../../partials/DataTable';
import { ActionsColumn } from './partials/ActionsColumn';
import CountryFilter from '../partials/Filters/CountryFilter';
import SearchFilter from '../partials/Filters/TextFilter';
import useUpdateTableEvent from '../../../partials/DataTable/hooks/useUpdateTableEvent';
import { publisherCountrySelector } from '../../../selectors/publisher';
import CopyTextButton from '../partials/CopyTextButton/CopyTextButton';

const Content = styled.div`
  width: 80%;
  margin: 2rem auto;

  ${Tag} {
    text-transform: uppercase;
    margin-left: 0.75rem;
  }

  ${Body1} {
    width: 100%;
  }
`;

const Toolbar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const Filters = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${spacing.value(SPACING_SIZE.M)};
  ${SearchFilter} {
    min-width: 30%;
  }
`;

const NameWithCopyId = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &:hover {
    ${CopyTextButton} {
      visibility: visible;
    }
  }

  ${CopyTextButton} {
    visibility: hidden;
  }
`;

function Modals() {
  const updateTableEvent = useUpdateTableEvent();
  return (
    <>
      <CreateCampaignModal onSuccessEvent={updateTableEvent} />
      <RenewCampaignModal onSuccessEvent={updateTableEvent} />
      <EditCampaignModal onSuccessEvent={updateTableEvent} />
    </>
  );
}

export function Campaigns() {
  useMount(() => dispatch(DEVELOPERS_REQUESTED));
  const onCreateCampaign = useCallback(() => dispatch(CREATE_NEW_CAMPAIGN), []);
  const defaultCountry = useSelector(publisherCountrySelector);
  if (defaultCountry == null) {
    return null;
  }
  const context = {
    collectionName: COLLECTION_NAME,
    defaultSorting: {},
    defaultFilters: {
      country: defaultCountry,
    },
    initialItemsPerPage: ROWS_PER_PAGE.TWENTY_FIVE,
    adapter: createCampaignsTableAdapter(),
  };
  return (
    <Layout>
      <DataTableProvider {...context}>
        <Content>
          <Toolbar>
            <Filters>
              <SearchFilter
                name="developer"
                placeholder="Search by developer name or uuid"
              />
              <CountryFilter source="country" />
              <SearchFilter
                name="project"
                placeholder="Search by project name or uuid"
              />
            </Filters>
            <Button onClick={onCreateCampaign}>Create new campaign</Button>
          </Toolbar>
          <DataTable allowPagination>
            <TextColumn
              id="project"
              renderField={({ projectId, projectName }) => (
                <NameWithCopyId>
                  {projectName}
                  <CopyTextButton title="Copy project id" text={projectId} />
                </NameWithCopyId>
              )}
              title="Project"
            />
            <TextColumn
              id="developer"
              renderField={({ developerId, developerName }) => (
                <NameWithCopyId>
                  {developerName}
                  <CopyTextButton
                    title="Copy developer id"
                    text={developerId}
                  />
                </NameWithCopyId>
              )}
              title="Developer"
            />
            <TextColumn
              id="leadTarget"
              source="leadTarget"
              title="Target leads"
            />
            <PriceColumn
              id="price"
              source="price"
              title="Total contract value"
            />
            <TextColumn
              id="billingCycle"
              renderField={({ billingCycleInMonths }) =>
                `${billingCycleInMonths} month/s`
              }
              title="Contracted months"
            />
            <TextColumn
              id="startDate"
              source={{ startDate: 'startDate' }}
              title="Start date"
              renderField={({ startDate }) => (
                <CustomFormattedDate
                  value={new Date(startDate)}
                  timeZone="utc"
                />
              )}
            />
            <TextColumn
              id="billingDate"
              source={{ billingDate: 'billingDate', status: 'status' }}
              renderField={({ billingDate, status }) => (
                <>
                  <CustomFormattedDate
                    value={new Date(billingDate)}
                    timeZone="utc"
                  />
                  {status === 'about to expire' && (
                    <Tag variant={TAG_VARIANT.COMPLEMENTARY_ORANGE}>
                      About to expire
                    </Tag>
                  )}
                  {status === 'grace period' && (
                    <Tag variant={TAG_VARIANT.COMPLEMENTARY_STRAWBERRY}>
                      Grace period
                    </Tag>
                  )}
                  {status === 'expired' && (
                    <Tag variant={TAG_VARIANT.DEFAULT}>Expired</Tag>
                  )}
                  {status === 'finished' && (
                    <Tag variant={TAG_VARIANT.DEFAULT}>Finished</Tag>
                  )}
                </>
              )}
              title="Billing date"
            />
            <ActionsColumn
              id="actions"
              source={{ campaignId: 'id', status: 'status' }}
              title="Actions"
            />
          </DataTable>
          <Modals />
        </Content>
      </DataTableProvider>
    </Layout>
  );
}
