import { publisherSelector } from '../../../../selectors/publisher';
import { showInvoicesSelector } from '../../../../selectors/config';

export function showProformaLinkSelector(state) {
  return (
    !publisherSelector(state)?.hasGenericBillingEntity &&
    showInvoicesSelector(state)
  );
}

export function proformaLinkSelector(state) {
  return state.proformaLink;
}
