import { useSelector } from 'reffects-store';
import { useEffect } from 'react';
import { dispatch } from 'reffects';
import { hasSelfServicePublisherSelector } from './selectors';
import { useScript } from '../../hooks/useScript';
import { TRACK_ELEMENT_IS_VISIBLE } from '../../partials/Tracking/events';

function WithHotJar({ children, id, version }) {
  useScript(`https://static.hotjar.com/c/hotjar-${id}.js?sv=${version}`, {
    removeOnUnmount: true,
  });
  return children;
}

function removeSelfServiceNotificationId() {
  const currentUrl = window.location.href;
  const parsedUrl = new URL(currentUrl);
  const params = parsedUrl.searchParams;
  let notificationId = null;

  if (params.has('selfServiceNotificationId')) {
    notificationId = params.get('selfServiceNotificationId');
    params.delete('selfServiceNotificationId');
  }

  return { currentUrl, updatedUrl: parsedUrl.toString(), notificationId };
}

export function CheckSelfService({ children }) {
  const isSelfService = useSelector(hasSelfServicePublisherSelector);

  const { currentUrl, updatedUrl, notificationId } =
    removeSelfServiceNotificationId();
  useEffect(() => {
    if (notificationId) {
      dispatch({
        id: TRACK_ELEMENT_IS_VISIBLE,
        payload: {
          name: `self-service-publisher-checkout-id-${notificationId}`,
          sectionName: 'self-service-checkout',
          visible: true,
        },
      });
    }
  }, [notificationId]);

  useEffect(() => {
    if (currentUrl !== updatedUrl && notificationId) {
      window.history.replaceState({}, '', updatedUrl);
    }
  }, [currentUrl, updatedUrl, notificationId]);

  // eslint-disable-next-line no-underscore-dangle
  const { hjid, hjsv } = window._hjSettings;
  return isSelfService && hjid ? (
    <WithHotJar id={hjid} version={hjsv}>
      {children}
    </WithHotJar>
  ) : (
    children
  );
}
