import styled, { css } from 'styled-components';
import { useMount } from 'react-use';
import { dispatch } from 'reffects';
import { BrandLogoForAnonymousUsers } from '../../partials/BrandLogo';
import OffersCards from './partials/OffersCards';
import {
  OFFERS_RETRIEVE_COUNTRY_CONFIG,
  OFFERS_RETRIEVE_EMAIL_TRACKING,
} from './events';

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;

  ${(props) =>
    props.theme.isMobile &&
    css`
      ${OffersCards} {
        height: calc(100% - 88px);
      }
    `}
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  min-height: 88px;
`;

const HeaderContent = styled.div`
  display: flex;
  width: 100%;
  max-width: 1280px;
  padding: 0 2em;
  align-items: center;
`;

export default function OffersPage() {
  useMount(() => {
    dispatch(OFFERS_RETRIEVE_COUNTRY_CONFIG);
    dispatch(OFFERS_RETRIEVE_EMAIL_TRACKING);
  });

  return (
    <Wrapper>
      <Header>
        <HeaderContent>
          <BrandLogoForAnonymousUsers role="img" />
        </HeaderContent>
      </Header>
      <OffersCards />
    </Wrapper>
  );
}
