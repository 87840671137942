import styled from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { radius, RADIUS_SIZE } from 'design-system/styles/radius';
import { Body2 } from 'design-system/components/Typography/presets/Body2';
import { FONT_WEIGHT } from 'design-system/styles/typography';
import Price from '../../../../partials/Price/Price';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';

const Wrapper = styled.div`
  ${radius.regular(RADIUS_SIZE.BASE)}
  ${color.background(COLOR_PALETTE.PRIMARY_A10)}
  ${spacing.inset(SPACING_SIZE.S)}
`;

export default function RenewalPriceDisclaimer({
  renewalPrice: { amount, currency },
}) {
  return (
    <Wrapper>
      <Body2>
        <FormattedMessageWithValidation
          id="checkout_summary_renewal_price"
          values={{
            price: (
              <Body2 $weight={FONT_WEIGHT.MEDIUM}>
                <Price amount={amount} currency={currency} />
              </Body2>
            ),
          }}
        />
      </Body2>
    </Wrapper>
  );
}
