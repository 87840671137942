import { IS_LOGGED_IN } from '../pages/Authentication/constants';
import {
  androidAppDownloadLink,
  iosAppDownloadLink,
} from '../utils/proppitWebRouter';
import { countryConfigSelector } from '../selectors/config';
import { isPublisherFromSpainSelector } from '../selectors/publisher';
import { isBackofficeUserSelector } from '../selectors/user';
import { MOBILE_DEVICE } from '../constants/deviceType';

export function isUserLoggedInSelector(state) {
  return state[IS_LOGGED_IN];
}

export function shouldRedirectToFirstPaymentCheckoutSelector(state) {
  const isBackofficeUser = isBackofficeUserSelector(state);
  return state['publisher:hasActiveFirstPaymentCheckout'] && !isBackofficeUser;
}

export function adFormRulesByFieldSelector(state, field) {
  const countryConfig = countryConfigSelector(state);
  const { propertyType } = state['adForm:ad'];

  return countryConfig.adFormRules?.[propertyType]?.[field] ?? null;
}

export function isCountryConfigLoadedSelector(state) {
  const countryConfig = countryConfigSelector(state);
  return Object.keys(countryConfig).length > 0;
}

export function showInterstitialSelector(state) {
  if (isWhiteLabelSelector(state)) {
    return false;
  }
  if (state.interstitialClosed) {
    return false;
  }
  return isMobileSelector(state);
}

export function showMaintenanceSelector(state) {
  return state.maintenance;
}

export function isMobileSelector(state) {
  return state.device?.type === MOBILE_DEVICE;
}

export function isAndroidSelector(state) {
  return state.device?.os === 'Android';
}

export function isIOSSelector(state) {
  return state.device?.os === 'iOS';
}

export function appDownloadLinkSelector(state) {
  if (isAndroidSelector(state)) {
    return androidAppDownloadLink();
  }
  if (isIOSSelector(state)) {
    return iosAppDownloadLink();
  }
  return null;
}

export function showGlobalizaLogoSelector(state) {
  return isGlobalizaWhiteLabelSelector(state);
}

export function isWhiteLabelSelector(state) {
  return isGlobalizaWhiteLabelSelector(state);
}

function isGlobalizaWhiteLabelSelector(state) {
  if (!isCountryConfigLoadedSelector(state)) {
    return state.websiteBrand === 'globaliza' ?? false;
  }
  return isPublisherFromSpainSelector(state);
}

export function showSitesToPublishSelector(state) {
  return !isWhiteLabelSelector(state);
}

export function showChurnForm(state) {
  return state['publisher:askChurnReason'] ?? false;
}
